import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PageTitle from "../common/PageTitle";
import Menu from "../menu/Menu";
import PageHeading from "../common/PageHeading";
import {setBg} from "../../actions/UIActions";
import Grain from "../common/Grain";

import publishedBooks from "./published-books";
import {changeCursorSize} from "../../actions/CursorActions";
import {DEFAULT_CURSOR_SIZE, BIG_CURSOR_SIZE} from "../../constants/cursorConstants";
import articles from "./articles";
import Footer from "../common/Footer";

function Writings(props) {
    const writings = [];

    useEffect(()=> {
        props.dispatch(setBg("#c52137"));
    },[])

    return (
        <>
            <PageTitle title="Writings | Ashish Avikunthak"/>
            <div>
                <Menu></Menu>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                >
                    <PageHeading title={"Writings"} style={{
                        color: "#ffffff"
                    }}/>
                    <div className={'writings'}>
                        <div>
                            <h2>
                                Published books
                            </h2>
                            {
                                publishedBooks &&
                                publishedBooks.map((item, index) => {
                                    return (
                                        <div className={'writings-details'} key={index}>
                                            <b>{item.date}</b>
                                            <div>
                                                <div>
                                                    {
                                                        item.link &&
                                                        <a
                                                            href={item.link}
                                                            target={"_blank"}
                                                            onMouseEnter={() => {
                                                                props.dispatch(changeCursorSize(BIG_CURSOR_SIZE))
                                                            }}
                                                            onMouseLeave={() => {
                                                                props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
                                                            }}
                                                        >
                                                            {item.title}
                                                        </a>
                                                    }
                                                    {
                                                        !item.link &&
                                                        item.title
                                                    }
                                                </div>
                                                <div>{item.publisher}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div>
                            <h2>
                                Selected Articles
                            </h2>
                            {
                                articles &&
                                articles.map((item, index) => {
                                    return (
                                        <div className={'writings-details'} key={index}>
                                            <b>{item.date}</b>
                                            <div>
                                                <div>
                                                    {
                                                        item.link &&
                                                        <a
                                                            href={item.link}
                                                            target={"_blank"}
                                                            onMouseEnter={() => {
                                                                props.dispatch(changeCursorSize(BIG_CURSOR_SIZE))
                                                            }}
                                                            onMouseLeave={() => {
                                                                props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
                                                            }}
                                                        >
                                                            {item.title}
                                                        </a>
                                                    }
                                                    {
                                                        !item.link &&
                                                        item.title
                                                    }
                                                </div>
                                                <div>{item.publisher}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <Grain opacity={0.1}/>
        </>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

export default connect(mapStateToProps)(Writings);