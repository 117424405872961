import React, {useEffect} from "react";
import {connect} from 'react-redux';
import { useParams } from 'react-router-dom';
import PageTitle from "../../common/PageTitle";
import Menu from "../../menu/Menu";
import PageHeading from "../../common/PageHeading";
import Grain from "../../common/Grain";
import Vimeo from "@u-wave/react-vimeo";
import shortFilmsList from "../shortFilms/short-films-list";
import featureFilmsList from "../featureFilms/feature-films-list";
import FilmCredits from "./FilmCredits";
import {useMediaQuery} from "react-responsive";
import Footer from "../../common/Footer";
import FilmGallery from "./FilmGallery";
import {setBg} from "../../../actions/UIActions";

function FilmPage(props) {
    const [title, setTitle] = React.useState("");
    const [filmObject, setFilmObject] = React.useState({});
    const { filmId } = useParams();

    const [listOfImages, setListOfImages] = React.useState([]);
    const filmsList = [...shortFilmsList,...featureFilmsList];
    const isMobile = useMediaQuery({ query: '(max-width: 479px)' });

    useEffect(()=> {
        props.dispatch(setBg("#ffffff"));
    },[]);

    useEffect(() => {
        setTitle(filmId
            .replaceAll("-"," ")
            .replace("—","/")
        );
    },[filmId]);

    useEffect(() => {
        setFilmObject(
            (filmsList.find((elem) => elem.title === title) || {})["details"] || {}
        );
    }, [title]);

    useEffect(() => {
        setListOfImages(filmObject.images || [])
    }, [filmObject]);

    return (
        <>
            <PageTitle title={`${title} | Ashish Avikunthak`}/>
            <div>
                <Menu></Menu>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                >
                    <PageHeading title={filmObject.title}/>
                    {
                        (<div className={'film-carousel'}>
                            {
                                listOfImages.map(
                                    (image, index) =>    <img key={index} src={image} alt="info"></img>
                                )
                            }
                        </div>)
                    }
                    <div className={'film-details'}>
                        <div className={'film-details-1'}>
                            {filmObject.description}
                        </div>
                        <div className={'film-details-2'}>
                            {
                                filmObject.trailer &&
                                <Vimeo
                                    className={'film-trailer'}
                                    video={filmObject.trailer}
                                    background={true}
                                    loop={true}
                                    volume={1}
                                    // onLoaded={(e) => {console.log(e)}}
                                    responsive={!isMobile}
                                    height={0.8*window.innerWidth}
                                />
                            }
                            {
                                !filmObject.trailer &&
                                <FilmGallery listOfImages={listOfImages}/>
                            }
                            <div className={'film-specs'}>
                                {
                                    filmObject.details &&
                                    filmObject.details.map((item,index) => {
                                        return <div key={index}>{item}</div>
                                    })
                                }
                            </div>
                        </div>
                        <div className={'film-details-3'}>
                            {
                                filmObject.credits &&
                                filmObject.credits.map((item,index) => {
                                    return <FilmCredits key={index} credits={item}/>
                                })
                            }
                        </div>
                        <div className={'film-details-4'}>
                            <h2>
                                Screenings
                            </h2>
                            {
                                filmObject.screenings &&
                                filmObject.screenings.map((item,index) => {
                                    return (
                                        <div key={index}>
                                            <b>{item[0]}</b>
                                            <span>{item[1]}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <Grain/>
        </>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

export default connect(mapStateToProps)(FilmPage);