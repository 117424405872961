import pkSurendran from "../../../assets/press/P.K. Surendran in Madhyamam Weekly. .pdf";
import maurizio from "../../../assets/press/An Interview with Ashish Avikunthak by Maurizio Calbi.pdf";
import aparnaFrank from "../../../assets/press/Tales of Long Takes by Aparna Frank.pdf";
import banglaBlack from "../../../assets/press/Bangla Black, Kolkata – Interview with Ashish Avikunthak.pdf";
import eiSomoy from "../../../assets/press/Ei Somay, Kolkata – Kumbher Shandhaner Chitrakar.pdf";
import abhisekhHazra from "../../../assets/press/Erasure of the Subaltern as Auto Critique.pdf";
import dawood from "../../../assets/press/Exchange with Shezad Dawood in Back Sun.pdf";
import kamyani from "../../../assets/press/Image Music Text by Kamayani Sharma in Art India..pdf";
import amrit from "../../../assets/press/Interviewed by Amrit Gangar.pdf";
import ncpa from "../../../assets/press/National Center for Performing Arts Catalogue.pdf";
import shakespeare from "../../../assets/press/Performing Shakespeare and Kathakali in Dancing Othello.pdf";
import timeOut1 from "../../../assets/press/Time Out, Mumbai – Graphic Detail by Zeenat Nagre.pdf";
import timeOut2 from "../../../assets/press/Time Out, Mumbai – Past Master by Zeenat Nagre.pdf";
import fringe from "../../../assets/press/From the Fringe of the Fringe.jpg";
import hindustanTimes from "../../../assets/press/Hindustan Times, Mumbai - Moving Images.jpg";
import abp from "../../../assets/press/Jal-Panir Takae Toiri Bangal Chobi Locarno Te.jpg";
import mooch from "../../../assets/press/Man Behind the Mooch.jpg";
import hindu from "../../../assets/press/The Hindu, Trivandrum - Journey into Women's Mind.jpg";

const pressList = [
    {
        date: 2024,
        title: "Outlook India - I Don’t Have Any Desire to Replicate the World In My Films: Filmmaker Ashish Avikunthak On His Film 'Devastated'",
        link: "https://www.outlookindia.com/art-entertainment/i-dont-have-any-desire-to-replicate-the-world-in-my-films-filmmaker-ashish-avikunthak-on-his-film-devastated",
    },
    {
        date: 2024,
        title: "ArtReview - Vidvastha (Devastated) Review: The Bizarre and the Bhagavad Gita",
        link: "https://artreview.com/vidvastha-devastated-ashish-avikunthak-review/"
    },
    {
        date: 2024,
        title: "Far Out Magazine, London – ‘Devastated’: Ashish Avikunthak’s reconfiguration of Indian political cinema",
        link: "https://faroutmagazine.co.uk/devastated-ashish-avikunthak-indian-political-cinema/"
    },
    {
        date: 2024,
        title: "Asian Movie Pulse Film – Review: Devastated (2024) by Ashish Avikunthak",
        link: "https://asianmoviepulse.com/2024/01/film-review-devastated-2024-by-ashish-avikunthak/"
    },
    {
        date: 2024,
        title: "Texts and Contexts: Revisiting Kalkimanthankatha by Adreeta Chakraborty in ASAP | art.",
        link: "https://asapconnect.in/post/672/singlestories/texts-and-contexts"
    },
    {
        date: 2024,
        title: "Medium and Narrative: On Kalkimanthankatha by Adreeta Chakraborty in ASAP | art.",
        link: "https://asapconnect.in/post/673/singlestories/medium-and-narrative"
    },
    {
        date: 2023,
        title: "Times of India – ‘Devastated’ World Premiere at Rotterdam Film Festival",
        link: "https://timesofindia.indiatimes.com/city/kolkata/devastated-world-premiere-at-rotterdam-film-festival/articleshow/106199718.cms"
    },
    {
        date: 2023,
        title: "Far Out Magazine, London – ‘Rummaging for Pasts’: an Ashish Avikunthak exhibition at Chatterjee and Lal",
        link: "https://faroutmagazine.co.uk/rummaging-for-pasts-ashish-avikunthak-exhibition/"
    },
    {
        date: 2023,
        title: "Bose, Brinda. \"Beckett and Avikunthak: Lineages of the Avant-Garde.\" Frontiers of South Asian Culture. Routledge 108-120.",
        link: "https://www.taylorfrancis.com/chapters/edit/10.4324/9781003428572-11/beckett-avikunthak-brinda-bose"
    },
    {
        date: 2023,
        title: "PK Surendran - Matrrbhoomi July 2023 - മാതൃഭൂമി - മതത്തെചോദ്യം ചെ യ്യുന്ന.",
        link: ""
    },
    {
        date: 2023,
        title: "On Cinematic Transformations, Gender, and Religion: An Interview with Ashish Avikunthak. In: Chakraborty Paunksnis, R., Paunksnis, Š. (eds) Gender, Cinema, Streaming Platforms. Palgrave Macmillan, pp. 247-67.",
        link: ""
    },
    {
        date: 2022,
        title: "Indian Express – Bureaucratic Archaeology is a scholarly investigation of the history and hierarchical structure of the ASI",
        link: "https://indianexpress.com/article/books-and-literature/bureaucratic-archaeology-scholarly-investigation-of-history-hierarchical-structure-8275688/"
    },
    {
        date: 2022,
        title: "A review of \"Bureaucratic Archaeology. State, Science, and Past in Postcolonial India, di Ashish Avikunthak\", by Tommaso Bobbio and Simona Troilo in Quaderni storici, Rivista quadrimestrale, Issue 3, pp. 895-912.",
        link: "https://www.rivisteweb.it/doi/10.1408/108124"
    },
    {
        date: 2022,
        title: "Antiquity – Archaeology, ethnography, and epistemic critiques of the nation",
        link: "https://www.cambridge.org/core/journals/antiquity/article/abs/archaeology-ethnography-and-epistemic-critiques-of-the-nation/73FFED2CDABBA3B2190E271C954535E9"
    },
    {
        date: 2022,
        title: "Far Out Magazine, London – ‘Glossary of Non-Human Love’: Ashish Avikunthak and Infra-realist sci-fi.’",
        link: "https://faroutmagazine.co.uk/glossary-of-non-human-love-ashish-avikunthak-sci-fi/"
    },
    {
        date: 2022,
        title: "Homegrown, Mumbai – A Homegrown Avant-Garde Short Film Fuses Shakespeare’s Othello With Kathakali",
        link: "https://homegrown.co.in/homegrown-creators/a-homegrown-avant-garde-short-film-fuses-shakespeares-othello-with-kathakali"
    },
    {
        date: 2022,
        title: "BOMB, New York – Interview with Ashish Avikunthak by Arun A.K.",
        link: "https://bombmagazine.org/articles/2022/11/07/ashish-avikunthak/"
    },
    {
        date: 2021,
        title: "Translating Silence: Ashish Avikunthak’s Cinematographic Version of Come and Go, by Thirthankar Chakraborty in Beckett’s Voices/Voicing Beckett (Leiden: Brill), pp. 221-234.",
        link: "http://dx.doi.org/10.1163/9789004468382_017"
    },
    {
        date: 2021,
        title: "Screen Slate, New York – An Idiom Unto Itself: An Ashish Avikunthak Retrospective",
        link: "https://www.screenslate.com/articles/idiom-unto-itself-ashish-avikunthak-retrospective"
    },
    {
        date: 2021,
        title: "Far Out Magazine, London – Ashish Avikunthak on making cinema from the margins",
        link: "https://faroutmagazine.co.uk/ashish-avikunthak-making-cinema-from-margins/"
    },
    {
        date: 2021,
        title: "Rolling Stone, New Delhi – Art-House Filmmaker Ashish Avikunthak On How Philosophy Influences His Work",
        link: "https://rollingstoneindia.com/art-house-filmmaker-ashish-avikunthak-interview/"
    },
    {
        date: 2021,
        title: "Grazia, New Delhi – To Kill A Thinking Bird: A Brief Retrospective On Ashish Avikunthak’s Films",
        link: "https://www.grazia.co.in/people/to-kill-a-thinking-bird-a-brief-retrospective-on-ashish-avikunthaks-films-8111.html"
    },
    {
        date: 2021,
        title: "Mint, New Delhi – India at IFFR 2021: 'Glossary of Non-human Love' and 'Everything is Cinema'",
        link: "https://lifestyle.livemint.com/how-to-lounge/movies-tv/india-at-iffr-2021-glossary-of-non-human-love-and-everything-is-cinema-111624378650892.html"
    },
    {
        date: 2021,
        title: "Film Companion, Mumbai – Verbose, Tantric, and Challenging: The Films of Ashish Avikunthak",
        link: "https://www.filmcompanion.in/features/indepth-stories/streaming/ashish-avikunthak-mubi-retrospective-verbose-tantric-and-challenging-the-films-of"
    },
    {
        date: 2018,
        title: "അ ടി യ ന്ത രാ വസ്ഥക്കാ ല ത്തെഭീ ക ര രൂ പങ്ങൾ by P.K. Surendran in Madhyamam Weekly.",
        link: pkSurendran,
    },
    {
        date: 2017,
        title: "Times of India, Kolkata – Uncertified film screening at Kolkata gallery miffs CBFC",
        link: "https://timesofindia.indiatimes.com/city/kolkata/uncertified-film-screening-at-kolkata-gallery-miffs-cbfc/articleshow/57688357.cms"
    },
    {
        date: 2017,
        title: "Bangla Black, Kolkata – Interview with Ashish Avikunthak (in Bengali)",
        link: banglaBlack
    },
    {
        date: 2017,
        title: "Bengali film makes it to Berlin Film Festival, Times of India, Calcutta.",
        link: "https://timesofindia.indiatimes.com/city/kolkata/bengali-film-makes-it-to-berlin-festival/articleshow/56708734.cms"
    },
    {
        date: 2017,
        title: "Asian Age, Mumbai – On a wire, the Unpierced one",
        link: "https://www.asianage.com/life/art/300117/on-a-wire-the-unpierced-one.html"
    },
    {
        date: 2017,
        title: "Mint, Mumbai – Goddess in the Details: Aapothkalin Trikalika",
        link: "https://www.livemint.com/Leisure/NitmPqXhStfdcLqZQ3Ol8I/Goddess-in-the-details-Aapothkalin-Trikalika.html"
    },
    {
        date: 2016,
        title: "Exhausting the inexhaustible: reading the structure of Samuel Beckett’s Come and Go through Ashish Avikunthak’s Endnote, by Arka Chattopadhaya in Textual Practice (2016): 1-17.",
        link: "https://doi.org/10.1080/0950236X.2016.1187667"
    },
    {
        date: 2015,
        title: "Indian Express, Mumbai – Eyes Wide Open",
        link: "https://indianexpress.com/article/lifestyle/eyes-wide-open-2/"
    },
    {
        date: 2015,
        title: "The Hindu, Chennai – Outside the Boundaries",
        link: "https://www.thehindu.com/features/friday-review/outside-the-boundaries/article7109597.ece"
    },
    {
        date: 2015,
        title: "Review of The Churning of Kalki by Murtaza Valli in Art Forum (November issue, pp. 325).",
        link: "https://www.artforum.com/events/ashish-avikunthak-214415/"
    },
    {
        date: 2014,
        title: "Indian Express, Mumbai – Temporal Universe",
        link: "https://indianexpress.com/article/cities/mumbai/temporal-universe/"
    },
    {
        date: 2014,
        title: "Ashish Avikunthak 2014 – An interview in an online film journal Débordements.",
        link: "https://debordements.fr/ashish-avikunthak-2014-v-o/"
    },
    {
        date: 2014,
        title: "Image Music Text by Kamayani Sharma in Art India.",
        link: kamyani
    },
    {
        date: 2014,
        title: "Ashish Avikunthak by Niru Ratnam in Art Review.",
        link: "https://artreview.com/2014-futuregreats-ashish-avikunthak/"
    },
    {
        date: 2014,
        title: "Exchange with Shezad Dawood in Back Sun: Alchemy, Diaspora, Hetrotopia (Arnolfini: Bristol).",
        link: dawood,
    },
    {
        date: 2014,
        title: "Ashish Avikunthak’s Rati Chakravyuh: Tales of a long-take by Aparna Frank in Dear Cinema (now defunct).",
        link: aparnaFrank,
    },
    {
        date: 2013,
        title: "East By Northwest – An interview in an online film journal Débordements.",
        link: "https://debordements.fr/ashish-avikunthak-v-o/"
    },
    {
        date: 2013,
        title: "Ei Somay, Kolkata – Kumbher Shandhaner Chitrakar (Bengali)",
        link: eiSomoy
    },
    {
        date: 2013,
        title: "Indian Express, Kochi – Charmed by transcendental magic",
        link: "https://www.newindianexpress.com/entertainment/2013/May/21/charmed-by-transcendental-magic-479295.html"
    },
    {
        date: 2013,
        title: "Indian Express, Kochi – Reinventing the mythical world",
        link: "https://www.newindianexpress.com/entertainment/2013/May/22/reinventing-the-mythical-world-479580.html"
    },
    {
        date: 2012,
        title: "Time Out, Mumbai – Past Master",
        link: timeOut2
    },
    {
        date: 2012,
        title: "Indian Express, Mumbai – The Art of Cinema",
        link: "https://indianexpress.com/article/entertainment/entertainment-others/the-art-of-cinema/"
    },
    {
        date: 2011,
        title: "Dancing Othello (Brihnnlala Ki Khelkali): An Interview with Ashish Avikunthak by Maurizio Calbi in Anglistica 15 (2).",
        link: maurizio,
    },
    {
        date: 2011,
        title: "Postcolonial Entanglements: Performing Shakespeare and Kathakali in Ashish Avikunthak’s Dancing Othello, by Maurizio Calbi in Anglistica 15 (2).",
        link: shakespeare
    },
    {
        date: 2010,
        title: "Time Out, Mumbai – Graphic Detail",
        link: timeOut1,
    },
    {
        date: 2010,
        title: "Hindustan Times, Mumbai - Moving Images",
        link: hindustanTimes
    },
    {
        date: 2009,
        title: "Yale Daily News, New Haven - A Profile: A Split Existence",
        link: "https://yaledailynews.com/blog/2009/01/16/profile-a-split-existence/"
    },
    {
        date: 2009,
        title: "National Center for Performing Arts Catalogue - Lead feature article in context of a retrospective at NCPA, Mumbai.",
        link: ncpa,
    },
    {
        date: 2007,
        title: "Ananda Bazzar Patrika, Calcutta – Jal-Panir Takae Toiri Bangal Chobi Locarno Te (Bengali)",
        link: abp,
    },
    {
        date: 2007,
        title: "Prado New, Locarno – From the Fringe of the Fringe",
        link: fringe,
    },
    {
        date: 2007,
        title: "Hindustan Times, New Delhi - Man Behind the Mooch: Ashish Avikunthak’s first film makes it to Locarno",
        link: mooch
    },
    {
        date: 2007,
        title: "The Hindu, Trivandrum - Journey into Women's Mind",
        link: hindu,
    },
    {
        date: 2007,
        title: "Erasure of the Subaltern as Auto Critique - An essay by Abhishek Hazra in the online contemporary Indian art webmagzine Artconcerns.",
        link: abhisekhHazra
    },
    {
        date: 2006,
        title: "Cinema Of Prayoga – extracts from the catalogue of Tate Modern, by Tanya Singh in the magazine Vertigo (Autumn '07).",
        link: "https://www.closeupfilmcentre.com/index.php?cID=1350&bID=808&arHandle=New+Leter&ccm_token=1512106702:be013da888a4677df9c0b68438faa781&btask=passthru&method=mailchimp_subscribe"
    },
    {
        date: 2006,
        title: "Interviewed by Amrit Gangar - in \"Cinema Prayoga: Indian Experimental Films 1913-2006\" eds., Brad Butler & Karen Mirza (London: no.w. here).",
        link: amrit
    },
];
export default pressList;