import {BURGER_OPEN, BURGER_TOGGLE} from "../constants/burgerConstants";

export function burgerOpen() {
    return {
        type: BURGER_OPEN,
        burgerOpen: true
    }
}

export function burgerToggle() {
    return {
        type: BURGER_TOGGLE
    }
}

export function burgerClose() {
    return {
        type: BURGER_OPEN,
        burgerOpen: false
    }
}