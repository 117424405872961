import importAll from "../../../util/importAll";

const DancingOthelloDetails = {
    title: "Dancing Othello | Brihnnlala Ki Khelkali",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Short Films Images/Dancing Othello/",
            false,
            /\.(png|jpe?g|JPG)$/)
    ),
    details: [
        "Color",
        "16mm",
        "18 minutes",
        "2002"
    ],
    description: "The film explores the moment of imaginative intersection of two seventeenth century classical artistic tradition- Shakespearean tragedy and South Indian dance form- Kathakali. Giving birth to a hybrid performance merging the epitome of English literature and the quintessence of Indian art. Situated in an ambivalent dramatic space, the Shakespearean English, as a symbol of a colonial language, collapses into the classical renderation of an orthodox dance form.",
    credits: [
        ["Directed, Produced & Written by","Ashish Avikunthak"],
        ["Cast","Arjun Raina"],
        ["Cinematography","Setu"],
        ["Editing","Neeraj Voralia"],
        ["Sound Recording","Julius Basaiawhoit"],
        ["Sound Design","Rishi Oberoi"],
],
    screenings: [
        ["2024","AS220 Project Space Gallery, Providence"],
        ["2023","Indian Institute of Technology, Mandi"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2021","MUBI online streaming platform"],
        ["2021","Body and Lens - International Screen Dance Festival and Seminar, Calcutta & Delhi"],
        ["2019","Serendipity Arts Festival, Goa"],
        ["2019","Kino Klub, Split, Croatia"],
        ["2018","Pungent Film Series, Athens, Grecce"],
        ["2017","Jawaharlal Nehru University, New Delhi"],
        ["2014","Plan9, Gallery Fotografic, Prague"],
        ["2014","Rice University Chao Film Festival, Houston"],
        ["2010","Royale with Cheese, Aicon Gallery, London"],
        ["2009","Gallery Chatterjee & Lal, Bombay"],
        ["2006","\"Desire under Banyan: Beyond Bollywood\", Pacific Film Archive, UC Berkeley"],
        ["2005","Retrospective of Indian Documentaries, Cinema-Nova Brussels"],
        ["2004","\"Shakespeare in Asia\", Conference on Theatre, Stanford University"],
        ["2002","\"Dance in South Asia\", Conference on Performance, Swarthmore College"],
],
}

export default DancingOthelloDetails;