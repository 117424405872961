import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PageTitle from "../../common/PageTitle";
import Menu from "../../menu/Menu";
import PageHeading from "../../common/PageHeading";
import shortFilmsList from "./short-films-list";
import FilmBox from "../FilmBox";
import Grain from "../../common/Grain";
import Footer from "../../common/Footer";
import {setBg} from "../../../actions/UIActions";

function ShortFilms(props) {

    useEffect(()=> {
        props.dispatch(setBg("#ffffff"));
    },[]);

    return (
        <>
            <PageTitle title="Short Films | Ashish Avikunthak"/>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    minHeight: "100vh",
                }}
            >
                <Menu></Menu>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                >
                    <PageHeading title={"Short Films"}/>
                    <div className={'films-container'}>
                        {shortFilmsList.map((film, index) => {
                            return <FilmBox
                                title={film.title}
                                thumbnail={film.thumbnail}
                                color={film.color}
                                key={index}/>
                        })}
                    </div>
                </div>
            </div>
            <Footer/>
            <Grain opacity={0.08}/>
        </>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

// export default  ShortFilms;
export default connect(mapStateToProps)(ShortFilms);