import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PageTitle from "../common/PageTitle";
import Menu from "../menu/Menu";
import PageHeading from "../common/PageHeading";
import interviewList from "./interviews-list";
import InterviewBox from "./InterviewBox";
import Grain from "../common/Grain";
import Footer from "../common/Footer";
import {setBg} from "../../actions/UIActions";

function Interview(props) {

    useEffect(()=> {
        props.dispatch(setBg("#c52137"));
    },[]);

    return (
        <>
            <PageTitle title="Interviews | Ashish Avikunthak"/>
            <div>
                <Menu></Menu>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                >
                    <PageHeading title={"Interviews"} style={{
                        color: "#ffffff"
                    }}/>
                    <div className={'interviews-container'}>
                        {interviewList.map((interview,index) => {
                            return <InterviewBox
                                title={interview.title}
                                href={interview.link}
                                key={index}/>
                        })}
                    </div>
                </div>
            </div>
            <Footer/>
            <Grain opacity={0.08}/>
        </>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

// export default Interview;
export default connect(mapStateToProps)(Interview);