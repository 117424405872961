import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PageTitle from "../../common/PageTitle";
import Menu from "../../menu/Menu";
import PageHeading from "../../common/PageHeading";
import featureFilmsList from "./feature-films-list";
import FilmBox from "../FilmBox";
import Grain from "../../common/Grain";
import Footer from "../../common/Footer";
import {setBg} from "../../../actions/UIActions";

function FeatureFilms(props) {

    useEffect(()=> {
        props.dispatch(setBg("#ffffff"));
    },[]);

    return (
        <>
            <PageTitle title="Feature Films | Ashish Avikunthak"/>
            <div>
                <Menu></Menu>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                >
                    <PageHeading title={"Feature Films"}/>
                    <div className={'films-container'}>
                        {featureFilmsList.map((film,index) => {
                            return <FilmBox
                                title={film.title}
                                thumbnail={film.thumbnail}
                                href={film.href}
                                color={film.color}
                                key={index}/>
                        })}
                    </div>
                </div>
            </div>
            <Footer/>
            <Grain opacity={0.08}/>
        </>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

// export default  FeatureFilms;
export default connect(mapStateToProps)(FeatureFilms);