import importAll from "../../../util/importAll";

const NamanushPremerKothamalaDetails = {
    title: "Namanush Premer Kothamala | Glossary of Non Human Love",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Feature Films Images/7 Namanush Premer Kothamala/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Bengali",
        "Color",
        "DCP",
        "96 minutes",
        "India",
        "2021"
    ],
    description: "In a parallel universe simultaneously existing in the present space-time continuum, Artificial Intelligence has possessed human beings. However, they have not been successful in forsaking the idea of love. This is an extract from their Operation Manual. In sixty-four words this film defines multiple contingencies in which the emotional life of human beings is colonized by the divine possibility of non-humans. The film wonders what does it mean to be humans in this world proliferated by non-humans?",
    credits: [
        ["Director & Producer","Ashish Avikunthak"],
        ["Additional Producer","Debleena Sen","Soumya Mukhopadhaya"],
        ["Cinematography","Basab Mullik"],
        ["Editing","Barnali Bose","Ashish Avikunthak"],
        ["Production Design","Sougata Mukherjee"],
        ["Sound Design","Sukanta Mazumdar"],
        ["Dialogue","Ashish Avikunthak","Debleena Sen","Sougata Mukherjee"],
        ["Cast" ,"Sagnik Mukherjee","Debleena Sen","Satarupa Das","Megha Roy","Prasanta Sarkar","Jaysree Mukherjee", "Bijoli Sarkar"]
    ],
    screenings: [
        ["*2021","World Premiere - International Film Festival Rotterdam"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2023","Prismatic Ground Film Festival, New York"],
        ["2022","Satyajit Ray Film and Television Institute, Kolkata"],
        ["2022","1st Pan-Cinema Experimental International Film Festival, Curitiba, Brazil"],
        ["2022","Gallery Chatterjee & Lal, Mumbai"],
],

    trailer: "433406027",
}

export default NamanushPremerKothamalaDetails;