import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {changeCursorSize, showCursorText} from "../../actions/CursorActions";
import {BIG_CURSOR_SIZE, DEFAULT_CURSOR_SIZE} from "../../constants/cursorConstants";
import {isMobile, isTablet} from "react-device-detect";

function CatalogBox(props) {

    const openInNewTab = (url)=> {
        window.open(url, '_blank').focus();
    }

    return (
        <div
            className={'catalog-box'}
            onClick={() => {
                props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE));
                openInNewTab(props.href);
            }}
        >
            <div className={'catalog-img'}>
                <img
                    onMouseEnter={() => {
                        props.dispatch(changeCursorSize(BIG_CURSOR_SIZE))
                    }}
                    onMouseLeave={() => {
                        props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
                    }}
                    src={props.thumbnail}
                    alt={props.title}
                />
            </div>
            <div className={'catalog-title'}>
                <h2>
                    {props.title}
                </h2>
            </div>

        </div>
    );
}

export default connect()(CatalogBox);