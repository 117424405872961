import Antaral from '../../../../assets/film-images/Short Films Images/Antaral_ End Note/thumbnail/thumbnail.jpg';
import DancingOthello from '../../../../assets/film-images/Short Films Images/Dancing Othello/thumbnail/thumbnail.jpg';
import Etcetra from '../../../../assets/film-images/Short Films Images/Etcetera/thumbnail/thumbnail.jpg';
import KalighatFetish from '../../../../assets/film-images/Short Films Images/Kalighat Fetish/thumbnail/thumbnail.jpg';
import PerformingDeath from '../../../../assets/film-images/Short Films Images/Performing Death/thumbnail/thumbnail.jpg';
import RummagingForPasts from '../../../../assets/film-images/Short Films Images/Rummaging For Pasts/thumbnail/thumbnail.jpg';
import VakratundaSwaha from '../../../../assets/film-images/Short Films Images/Vakratunda  Swaha/thumbnail/thumbnail.jpg';
import EtCeteraDetails from "./et-cetera";
import KalighatFetishDetails from "./kalighat-fetish";
import DancingOthelloDetails from "./dancing-othello";
import RummagingForPastsDetails from "./rummaging-for-pasts";
import PerformingDeathDetails from "./performing-death";
import AntaralDetails from "./antaral";
import VakratundaSwahaDetails from "./vakratunda-swaha";


const shortFilmsList = [
    {
        title:'Vakratunda Swaha',
        thumbnail:VakratundaSwaha,
        details: VakratundaSwahaDetails,
        color:"#57664d"
    },
    {
        title:'Antaral/End Note',
        thumbnail:Antaral,
        details: AntaralDetails,
        color:"#c6c29c"
    },
    {
        title:'Performing Death',
        thumbnail:PerformingDeath,
        details: PerformingDeathDetails,
        color:"#cd4042"
    },
    {
        title:'Rummaging For Pasts',
        thumbnail:RummagingForPasts,
        details: RummagingForPastsDetails,
        color:"#8e4f76"
    },
    {
        title:'Dancing Othello',
        thumbnail:DancingOthello,
        details: DancingOthelloDetails,
        color:"#4d6846"
    },
    {
        title:'Kalighat Fetish',
        thumbnail:KalighatFetish,
        details: KalighatFetishDetails,
        color:"#17272c"
    },
    {
        title:'Etcetera',
        thumbnail:Etcetra,
        details: EtCeteraDetails,
        color:"#c19faf"
    },
];

export default shortFilmsList;