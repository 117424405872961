import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PageTitle from "../common/PageTitle";
import Menu from "../menu/Menu";
import PageHeading from "../common/PageHeading";
import {setBg} from "../../actions/UIActions";
import Grain from "../common/Grain";
import data from "./about-data";
import Footer from "../common/Footer";
import AboutImg from "./AboutImg";


function About(props) {

    useEffect(()=> {
        props.dispatch(setBg("#000000"));
    },[])

    return (
        <>
            <PageTitle title="About | Ashish Avikunthak"/>
            <div>
                <Menu></Menu>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                >
                    <PageHeading title={"About"} style={{
                        color: "#ffffff"
                    }}/>
                    <div className={'about'}>
                        <AboutImg imgs={data.about_pics}/>
                        <p>
                            {data.para1}
                        </p>
                        <p>
                            {data.para2}
                        </p>
                        <p>
                            {data.para3}
                        </p>
                        <p>
                            {data.para4}
                        </p>
                        <div>
                            <h2>
                                Contact
                            </h2>
                            <div>
                                <h3>
                                    {data.addr1.title}
                                </h3>
                                <h4>
                                    {data.addr1.addrln1}
                                </h4>
                                <h4>
                                    {data.addr1.addrln2}
                                </h4>
                            </div>
                            <div>
                                <h3>
                                    Email -
                                </h3>
                                <h4>
                                    {data.mail}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer/>
            <Grain opacity={0.1}/>
        </>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

export default connect(mapStateToProps)(About);