import importAll from "../../../util/importAll";

const NirakarChhayaDetails = {
    title: "Nirakar Chhaya | Shadows Formless",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Feature Films Images/1 Nirakar  Chhaya/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Bengali",
        "Color & B/W",
        "35mm",
        "82 minutes",
        "India",
        "2007"
    ],
    description: "A film trapped between two monologues. A lonely and abandoned wife’s fantasy comes to life when the paramour she invokes springs forth and transforms her reality. Shadows Formless is an interpretation of the Malayalam novella Pandavpuram by the distinguished novelist Setumadhavan from Kerela.",
    credits: [
        ["Cast","Mandira Banerjee", "Deepak Haldar", "Sweta Tiwari"],
        ["Director & Producer", "Ashish Avikunthak"],
        ["Executive Producer", "Ashwini Deo"],
        ["Cinematography", "Setu"],
        ["Editing", "Mahadeb Shi", "Pankaj Rishi Kumar"],
        ["Screenplay", "Moloy Mukherjee", "Ashish Avikunthak "],
        ["Story","Sethumadhvan"],
        ["Music","Nurit Jugend"],
        ["Sound","Kshtij Rajkumar","Rupak Das"],
        ["Associate Director","Moloy Mukherjee"]
    ],
    screenings: [
        ["*Best Director, Indo-American Arts Council Film Festival, New York"],
        ["*Best Actress, Indo-American Arts Council Film Festival, New York"],
        [],
        ["*2007","World Premiere - Locarno International Film Festival, Switzerland"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2021","MUBI online streaming platform"],
        ["2017","Kerala Literary festival, Kozhikode"],
        ["2015","Centre for Moving Image Arts, Bard College"],
        ["2014","Rice University Chao Film Festival, Houston"],
        ["2009","Asian Hot Shots, Berlin"],
        ["2009 ","Cornell University"],
        ["2008","International Forum of New Cinema, Calcutta"],
        ["2008","India Visions, Washington D.C."],
        ["2007 ","Indo-American Arts Council Film Festival, New York"],
        ["2008","International Film Festival Innsbruck, Austria"],
        ["2008","Singapore International Film Festival"],
    ],

    trailer: "465985631",
}

export default NirakarChhayaDetails;