import React from "react";
import Burger from "./Burger";
import titles from "./menu-links";
import PageLinkCard from "./PageLinkCard";
import {connect} from "react-redux";

function MenuMobile(props) {
    return (
        <>
            <div
                style={{
                    flex: 1,
                    marginRight: "4em",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                }}>
                <div className={'menu-mobile menu-mobile-desktop'+(!props.burgerOpen?' hidden':'')}>
                    {
                        titles.map((title, index) => {
                            return <PageLinkCard key={index} title={title.title} href={title.href}/>
                        })
                    }
                </div>
                <Burger/>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        burgerOpen: state.burgerReducer.burgerOpen,
    };
}

export default connect(mapStateToProps)(MenuMobile);