import React, {useEffect} from "react";

const PreloaderAnimation = (props) => {

    return (
        <div className={'preloader-animation'}>
        </div>

    );
}

export default PreloaderAnimation;