import importAll from "../../../util/importAll";

const PerformingDeathDetails = {
    title: "Performing Death",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Short Films Images/Performing Death/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Color",
        "DV",
        "15 minutes",
        "2001"
    ],
    description: "This is a video about the Park Street cemetery in Calcutta - one of the earliest colonial cemetery in the world – its degrading conditions and its status as an abandoned legacy of an empire lost and forgotten",
    credits: [
        ["Directed, Produced & Written by","Ashish Avikunthak"],
        ["Cinematogrpahy","Ranjan Palit"],
        ["Editing","Yaman Kalyan"],
],
    screenings: [
        ["*2002","World Premiere - European Association of Archaeology, Thessaloniki"],
        ["2023","Chatterjee & Lal, Mumbai"],
        ["2021","MUBI online streaming platform"],
        ["2020","Marupakkam Online Film Festival: Artists Cinema, Chennai"],
],
}

export default PerformingDeathDetails;