import React from "react";

function PageHeading(props) {
    return (
        <div className={'page-heading'} style={{
            ...props.style
        }}>
            {props.title}
        </div>
    );
}

export default PageHeading;