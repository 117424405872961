const publishedBooks = [
    {
        date: 2024,
        title: "Rati Chakravyuh: Screenplay, Analysis, and Interview",
        publisher: "Boibhashik Publishers, Kolkata (original screenplay in Bangla with analysis and interview in English)",
        link: "https://www.amazon.in/Chakravyuh-Interview-Bestselling-Avikunthak-Trending/dp/B0CVB46JF6/"
    },
    {
        date: 2021,
        title: "Bureaucratic Archaeology: State, Science and Past in Postcolonial India",
        publisher: "South Asia in the Social Sciences, Series Number 17, Cambridge University Press",
        link: "https://www.cambridge.org/core/books/bureaucratic-archaeology/6025AC68E8F979371331B5369F9B8DCA"
    },
    {
        date: 2020,
        title: "Totem Sprit",
        publisher: "Hawakal Press, Calcutta & New Delhi",
        link: "https://www.amazon.in/Totem-Spirit-Ashish-Avikunthak/dp/8194665159/"

    },
    {
        date: 2020,
        title: "Nirakar Chhaya: Screenplay",
        publisher: "Logos Books, Pattambi, Kerala (Malayalam translation of the Bangla screenplay)",
    },
];

export default publishedBooks;