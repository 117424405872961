import NirakarChhaya from '../../../../assets/film-images/Feature Films Images/1 Nirakar  Chhaya/thumbnail/thumbnail.jpg';
import KathoUpanishad from '../../../../assets/film-images/Feature Films Images/2 Katho Upanishd/thumbnail/thumbnail.jpg';
import RatiChakravyuh from '../../../../assets/film-images/Feature Films Images/3 Rati Chakravyuh/thumbnail/thumbnail.jpg';
import Kalkimanthankatha from '../../../../assets/film-images/Feature Films Images/4 Kalkimanthankatha/thumbnail/thumbnail.jpg';
import AapothkalinTrikalika from '../../../../assets/film-images/Feature Films Images/5 Aapothkalin Trikalika/thumbnail/thumbnail.jpg';
import VrindavaniVairagya from '../../../../assets/film-images/Feature Films Images/6 Vrindavani Vairagya/thumbnail/thumbnail.jpg';
import NamanushPremerKothamala from '../../../../assets/film-images/Feature Films Images/7 Namanush Premer Kothamala/thumbnail/thumbnail.jpg';
import Vidhvastha from '../../../../assets/film-images/Feature Films Images/8 Vidhvastha/thumbnail/thumbnail.jpg';

import NirakarChhayaDetails from "./nirakar-chhaya";
import KathoUpanishadDetails from "./katho-upanishad";
import KalkimanthankathaDetails from "./kalkimanthankatha";
import RatiChakravyuhDetails from "./rati-chakravyuh";
import AapothkalinTrikalikaDetails from "./aapothkalin-trikalika";
import VrindavaniVairagyaDetails from "./vrindavani-vairagya";
import NamanushPremerKothamalaDetails from "./namanush-premer-kothamala";
import VidhvasthaDetails from "./vidhvastha";

const featureFilmsList = [
    {
        title:'Vidhvastha',
        thumbnail:Vidhvastha,
        details: VidhvasthaDetails,
        color:"#4b5377"
    },
    {
        title:'Namanush Premer Kothamala',
        thumbnail:NamanushPremerKothamala,
        details: NamanushPremerKothamalaDetails,
        color:"#cd3849"
    },
    {
        title:'Vrindavani Vairagya',
        thumbnail:VrindavaniVairagya,
        details: VrindavaniVairagyaDetails,
        color:"#92aeab"
    },
    {
        title:'Aapothkalin Trikalika',
        thumbnail:AapothkalinTrikalika,
        details: AapothkalinTrikalikaDetails,
        color:"#2a3233"
    },
    {
        title:'Kalkimanthankatha',
        thumbnail:Kalkimanthankatha,
        details: KalkimanthankathaDetails,
        color:"#2e2e2e"
    },
    {
        title:'Rati Chakravyuh',
        thumbnail:RatiChakravyuh,
        details: RatiChakravyuhDetails,
        color:"#ce1812"
    },
    {
        title:'Katho Upanishad',
        thumbnail:KathoUpanishad,
        details: KathoUpanishadDetails,
        color:"#53574e"
    },
    {
        title:'Nirakar Chhaya',
        thumbnail:NirakarChhaya,
        details: NirakarChhayaDetails,
        color:"#c7c8c3"
    },
];

export default featureFilmsList;