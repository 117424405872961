import {PRELOADED, SET_BG, SKETCH_EXPLORED} from "../constants/UIConstants";

export function uiReducer(state= {}, action) {
    switch (action.type){
        case SET_BG:
            return {
                ...state,
                color: action.color
            }
        case SKETCH_EXPLORED:
            return {
                ...state,
                sketchExplored: true
            }
        case PRELOADED:
            return {
                ...state,
                preloaded: true
            }
        default:
            return {
                ...state
            };
    }
}
