import React from "react";
import {connect} from 'react-redux';

import {changeCursorSize} from "../../actions/CursorActions";
import {DEFAULT_CURSOR_SIZE, BIG_CURSOR_SIZE} from "../../constants/cursorConstants";

function PressItem(props) {

    const item = props.data;
    return (
            <div className={'press-details'}>
                <b>{item.date}</b>
                <div>
                    {
                        item.link &&
                        <a
                            rel="noreferrer"
                            href={item.link}
                            target={"_blank"}
                            onMouseEnter={() => {
                                props.dispatch(changeCursorSize(BIG_CURSOR_SIZE))
                            }}
                            onMouseLeave={() => {
                                props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
                            }}
                        >
                            {item.title}
                        </a>
                    }
                    {
                        !item.link &&
                        item.title
                    }
                </div>
            </div>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

export default connect(mapStateToProps)(PressItem);