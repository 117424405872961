import React from "react";
import {connect} from 'react-redux';

function Footer(props) {

    return (
        <div className={'footer' + (props.bg !== "#ffffff" ? ' white' : '')}>
            <div>
                ASHISH AVIKUNTHAK
            </div>
            <div onClick={()=> {
                window.scrollTo({top:0, behavior: "smooth"});
            }}>
                back to top ↑
            </div>
            <div>
                <div>@avikunthak</div>
                <div>avikunthak@gmail.com</div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        burgerOpen: state.burgerReducer.burgerOpen,
        bg: state.uiReducer.color
    };
}

export default connect(mapStateToProps)(Footer);