import React, {useEffect} from "react";
import {connect} from 'react-redux';
import PageTitle from "../common/PageTitle";
import Menu from "../menu/Menu";
import PageHeading from "../common/PageHeading";
import catalogList from "./catalog-list";
import CatalogBox from "./CatalogBox";
import Grain from "../common/Grain";
import Footer from "../common/Footer";
import {setBg} from "../../actions/UIActions";

function Catalog(props) {

    useEffect(()=> {
        props.dispatch(setBg("#c52137"));
    },[]);

    return (
        <>
            <PageTitle title="Catalogs | Ashish Avikunthak"/>
            <div>
                <Menu></Menu>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                >
                    <PageHeading title={"Catalogs"} style={{
                        color: "#ffffff"
                    }}/>
                    <div className={'catalogs-container'}>
                        {catalogList.map((catalog,index) => {
                            return <CatalogBox
                                title={catalog.title}
                                href={catalog.link}
                                thumbnail={catalog.thumbnail}
                                key={index}/>
                        })}
                    </div>
                </div>
            </div>
            <Footer/>
            <Grain opacity={0.08}/>
        </>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

// export default Catalog;
export default connect(mapStateToProps)(Catalog);