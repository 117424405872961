import {BURGER_OPEN, BURGER_TOGGLE} from "../constants/burgerConstants";

export function burgerReducer(state= {}, action) {
    switch (action.type){
        case BURGER_OPEN:
            return {
                ...state,
                burgerOpen: action.burgerOpen
            }
        case BURGER_TOGGLE:
            return {
                ...state,
                burgerOpen: !state.burgerOpen
            }
        default:
            return {
            ...state
            };
    }
}
