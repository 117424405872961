import importAll from "../../../util/importAll";

const VidhvasthaDetails = {
    title: "Vidhvastha | Devastated ",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Feature Films Images/8 Vidhvastha/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Bengali",
        "Color",
        "DCP",
        "86 minutes",
        "India & Germany",
        "2022"
    ],
    description: "Present day India. A middle-aged police encounter-specialist (hit man) reveals his obsession with the assassinating Muslim men in a series of intense and intimate conversations with his wife and lover. Parallelly, a dialogue between Lord Krishna and Arjun from Bhagavad Gita unfolds. Arjun tells Krishna that he is unable to kill his cousins and elders. He asks Krishna to be his spiritual teacher and direct him along the right course of action. Krishna admonishes Arjun and says that he will be disgraced if he doesn't stand up for the right cause. He advises that he should fight for religious and moral duty.",
    credits: [
        ["Featuring","Mainak Dasgupta","Sagnik Mukherjee","Prasenjit Bardhan","Debleena Sen","Sanghamitra Deb","Sanghamitra Das"],
        ["Cinematography","Basab Mullik","Pratyush Bhattacharyya"],
        ["Editing","Barnali Bose","Ashish Avikunthak"],
        ["Sound Design","Sukanta Mazumdar"],
        ["Digital Colorist","Ritajit Raychaudhuri"],
        ["Dialogue","Sougata Mukherjee","Ashish Avikunthak"],
        ["Co-Producer","Soumya Mukhopadhaya","Kristina Konrad"],
        ["Executive Producer","Debleena Sen"],
        ["Screenplay, Director & Producer","Ashish Avikunthak"],

    ],
    screenings: [
        ["*2024", "World Premiere - International Film Festival Rotterdam"]
    ],

    trailer: "880782584",
}

export default VidhvasthaDetails;