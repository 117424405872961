import React from "react";
import {useLocation} from "react-router-dom";
import MenuMobile from "./MenuMobile";
import MenuDesktop from "./MenuMobileForDesktop";
import {isMobile, isTablet} from "react-device-detect";

function Menu(props) {

    const location = useLocation();
    const {  pathname } = location;
    return (
        <div className={'menu'} style={{
            ...props.style
        }}>
            {(isMobile || isTablet) && <MenuMobile pathname={pathname}/>}
            {!(isMobile || isTablet) && <MenuDesktop pathname={pathname}/>}
        </div>
    );
}

export default Menu;