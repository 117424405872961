import React, {useEffect} from "react";
import {connect} from 'react-redux';
import {CURSOR_MODE, TEXT_MODE} from "../../constants/cursorConstants";
import {isMobile, isTablet} from "react-device-detect";

function Cursor(props) {
    const [mouseX,setMouseX] = React.useState(0);
    const [mouseY,setMouseY] = React.useState(0);
    const [moveCursor, setMoveCursor] = React.useState(true);
    const hideCursor = props.hide?0:1;
    const cursorHandler = (event) => {
        if(moveCursor) {
            setMouseX(event.clientX);
            setMouseY(event.clientY);
            setMoveCursor(false);
            setTimeout(() => {
                setMoveCursor(true)
            },12);
        }
    };
    useEffect(() => {
        window.addEventListener("mousemove", cursorHandler);
        return( () => {
            window.removeEventListener("mousemove",cursorHandler);
        })

    }, []);

    if(isMobile || isTablet){
        return <></>
    }
    return (
        <div id={'cursor'}>
            {
                props.mode === TEXT_MODE
                &&
                (
                    <div style={{
                        position: "fixed",
                        background: "#fff",
                        pointerEvents: "none",
                        left: mouseX + "px",
                        top: mouseY + "px",
                        transform: "translate(-50%, -50%)",
                        transition: "all 0ms ease-in-out",
                        zIndex: 1001,
                        padding: "1em",
                        fontFamily: "Questrial",
                        fontSize: "1.2em"
                    }}>
                        {props.text}
                    </div>
                )
            }
            {
                props.mode === CURSOR_MODE
                &&
                (
                    <div style={{
                        position: "fixed",
                        background: "#fff",
                        height: 6*hideCursor + "px",
                        width: 6*hideCursor + "px",
                        borderRadius: "50%",
                        pointerEvents: "none",
                        left: mouseX + "px",
                        top: mouseY + "px",
                        transform: "translate(-50%, -50%)",
                        transition: "all 0ms ease-in-out",
                        zIndex: 1001,
                        mixBlendMode: "difference",
                    }}>
                    </div>
                )}
                <div style={{
                        position: "fixed",
                        background: "transparent",
                        height: hideCursor*props.size*2 + "px",
                        width: hideCursor*props.size*2 + "px",
                        border: "#fff solid 2px",
                        borderWidth: 1.5*hideCursor +"px",
                        borderRadius: "100%",
                        pointerEvents: "none",
                        transform: `translate3d(calc(${mouseX}px - 50%), calc(${mouseY}px - 50%), 0)`,
                        transition: "all 0.3s ease-out",
                        zIndex: 1000,
                        mixBlendMode: "difference",
                }}>
                </div>
        </div>
)
    ;
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize,
        hide: state.cursorReducer.cursorHide,
        mode: state.cursorReducer.mode,
        text: state.cursorReducer.cursorText
    };
}

export default connect(mapStateToProps)(Cursor);