import importAll from "../../../util/importAll";

const RatiChakravyuhDetails = {
    title: "Rati Chakravyuh",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Feature Films Images/3 Rati Chakravyuh/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Bengali",
        "Color",
        "DCP",
        "106 minutes",
        "India & Germany",
        "2013"
    ],
    description: "On a lunar eclipse midnight, in a desolate temple, six young newlywed couples and a priestess meet after a mass wedding. They sit in a circle and talk. This is their last conversation – an exchange about life, death, beginning, end and everything in between. After a discussion that lasts more than an hour and a half, they commit mass suicide.",
    credits: [
        ["Screenplay, Director & Producer","Ashish Avikunthak"],
        ["Director of Photography","Basab Mullick"],
        ["Dialogue","Ashish Avikunthak","Sougata Mukherjee"],
        ["Cast" , "Mandira Banerjee","Trisha Barua","Joyraj Bhattacahrya","Saswati Biswas","Sahali Goswami","Mishka Halim","Sharmistha Nag","Suman Nandi","Satakshi Nandi","Kaushik Pan","Supratim Ray","Udvas Roy","Debdip Sen"]
    ],
    screenings: [
        ["*Best Director, Indo-American Arts Council Film Festival, New York"],
        ["*Best Actress, Indo-American Arts Council Film Festival, New York"],
        [],
        ["*2007","World Premiere - Locarno International Film Festival, Switzerland"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2021","MUBI online streaming platform"],
        ["2017","Kerala Literary festival, Kozhikode"],
        ["2015","Centre for Moving Image Arts, Bard College"],
        ["2014","Rice University Chao Film Festival, Houston"],
        ["2009","Asian Hot Shots, Berlin"],
        ["2009 ","Cornell University"],
        ["2008","International Forum of New Cinema, Calcutta"],
        ["2008","India Visions, Washington D.C."],
        ["2007 ","Indo-American Arts Council Film Festival, New York"],
        ["2008","International Film Festival Innsbruck, Austria"],
        ["2008","Singapore International Film Festival"],
    ],

    trailer: "97719308",
}

export default RatiChakravyuhDetails;