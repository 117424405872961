import React from "react";

function Motif(props) {
    return (
        <div className={'motif'} style={{
            display: "flex",
            position:  "absolute",
            left: props.x,
            top: props.y,
            // background:  "#c52137",
            transform: "translate(-50%,-50%)",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none"
        }}>
            <img src={props.src}/>
        </div>
    );
}

export default (Motif);