import {combineReducers} from 'redux';
import {cursorReducer} from "./CursorReducer";
import {burgerReducer} from "./BurgerReducer";
import {uiReducer} from "./UIReducers";

/** Wizard UI Root Reducer */
const wizardState = combineReducers({
    cursorReducer,
    burgerReducer,
    uiReducer
});
export default wizardState;