import importAll from "../../../util/importAll";

const VakratundaSwahaDetails = {
    title: "Vakratunda Swaha",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Short Films Images/Vakratunda  Swaha",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Color",
        "35mm",
        "21 minutes",
        "2010"
    ],
    description: "In 1997, I filmed a sequence - a friend immersing an idol of Ganesha at Chowpati beach, Bombay on the last day of the Ganapati festival. A year later, he committed suicide. After twelve years, I completed the film. Using his footage as the leitmotif, this film is a requiem to a dead friend.",
    credits: [
        ["Directed, Produced & Written by","Ashish Avikunthak"],
        ["Cast","Girish Dahiwale","Debu Pramanik","Ashish Avikunthak","Swapan Pramanik","Radhika Apte"],
        ["Cinematography","Setu","Manoj Peter","Indranil Mukherjee","Lalit Tiwari","Praveen K. Bangari","Arindam Bhattacharjee","Anirudh Garbyal"],
        ["Sound","Preetam Das"],
        ["Editing","Pankaj Rishi Kumar"],
        ["Associate Director","Moloy Mukherjee"],
],
    screenings: [
        ["*2010","World Premiere - Hamburg International Short Film Festival"],
        ["2024","AS220 Project Space Gallery, Providence"],
        ["2023","Emami Art Experimental Film Festival, Calcutta"],
        ["2023","Indian Institute of Technology, Mandi"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2022","1st Pan-Cinema Experimental International Film Festival, Curitiba, Brazil"],
        ["2021","MUBI online streaming platform"],
        ["2020","16mm Film Festival, Harkat Studios, Mumbai"],
        ["2020","Asian Film Archive, Singapore"],
        ["2019","Mostra Strangloscope, Florianópolis, Brazil"],
        ["2019","Kino Klub, Split, Croatia"],
        ["2018","Pungent Film Series, Athens, Grecce"],
        ["2018","A Utopian Stage, Dhaka Art Summit, Bangladesh"],
        ["2017","Unruly Shadows, Nanyang Technological University, Centre for Contemporary Art, Singapore"],
        ["2016","Les Inattendus, Lyon"],
        ["2015","Centre for Moving Image Arts, Bard College"],
        ["2015","Apeejay Arts Gallery, New Delhi"],
        ["2014","Plan9, Gallery Fotografic, Prague"],
        ["2014","Rice University Chao Film Festival, Houston"],
        ["2014","Contemporary Art Platform, Kuwait"],
        ["2012","You Don’t Belong: Pasts and Futures of Indian Cinema, Hong Kong"],
        ["2012","Modern Monsters / Death and Life of Fiction, Taipei Biennial 2012"],
        ["2011","Human Frames, Kunst-im-Tunnel, Düsseldorf"],
        ["2011","Video in Progress 4: Feelings of Distress, Kolektiva, Ljubljana"],
        ["2011","You Don’t Belong: Pasts and Futures of Indian Cinema, Beijing, Shanghai, Guangzhou & Kunming"],
        ["2011","Generation in Transition. New Art from India, Zacheta National Gallery for Art, Warsaw"],
        ["2011","Paris-Delhi-Bombay, Centre George Pompidou, Paris"],
        ["2011","Rotterdam International Film Festival"],
        ["2010","Jordon International Film Festival"],
        ["2010","Gallery Chatterjee & Lal, Mumbai"],
],
}

export default VakratundaSwahaDetails;