
const interviewsList = [
    {
        title: "Remembering Kumar Shahani (Part 1)",
        link: "https://www.youtube.com/watch?v=x1XV9xPaY-U",
    },
    {
        title: "Remembering Kumar Shahani (Part 2)",
        link: "https://www.youtube.com/watch?v=pHgkQ8_SICw",
    },
    {
        title: "Dispassionate Love (2018) | Experimental Filmmaker Ashish Avikunthak With N. Rashed Chowdhury",
        link: "https://www.youtube.com/watch?v=H86ZMS_B9Jo",
    },
    {
        title: "IG LIVE: Ashish Avikunthak in conversation with Ashish Rajadhyaksha",
        link: "https://www.youtube.com/watch?v=R9DlBzjk0ws",
    },
    {
        title: "Ashish Avikunthak: Bureaucratic Archaeology, The Working of ASI",
        link: "https://www.youtube.com/watch?v=DJI1eY2qRGE",
    },
    {
        title: "How the Indian Art Market and the Gallery System Operate : A Conversation with Ashish Avikunthak",
        link: "https://www.youtube.com/watch?v=9YOYaBvR63k",
    },
    {
        title: "Q & A - Issues in Cinema",
        link: "https://www.youtube.com/watch?v=TwTqpO8_6gQ",
    },
    {
        title: "Interview With Ashish Avikuntak | At ISFFI Bhopal| Neelesh Singh Sisodia | 2020",
        link: "https://www.youtube.com/watch?v=dA6rxgIBy-s",
    },
    {
        title: "Audiogyan Ep. 14: A film artist",
        link: "https://www.youtube.com/watch?v=qx8A7EePPGg",
    },
    {
        title: "People of India Interview, 20 March 2017",
        link: "https://www.youtube.com/watch?v=dhkIpvQ068k",
    },
    {
        title: "Kalkimanthankatha | The Churning of Kalki Q&A at New York University, 2016",
        link: "https://www.youtube.com/watch?v=B7mQY7C3nbo",
    },
    {
        title: "Rati Chakravyuh Q&A at Slought, University of Pennsylvania, 2015",
        link: "https://www.youtube.com/watch?v=I-a1IImk5MA",
    },
    {
        title: "29 12 2012 Q & A Session with Ashish Avikunthak, Film Maker",
        link: "https://www.youtube.com/watch?v=xuKIe47VR-k",
    },
    {
        title: "29 12 2012 Mr. Amrit Gangar talks about Ashish and his work.",
        link: "https://www.youtube.com/watch?v=wa92oqgTLqk",
    },
    {
        title: "29 12 2012 Mr.Amrit Gangar talks about Ashish and his work. (Part II)",
        link: "https://www.youtube.com/watch?v=UXC9YD-d_pQ",
    },
    {
        title: "THE FD ZONE Film Club",
        link: "https://www.youtube.com/watch?v=s-nHA7UGyOs",
    },
];

export default interviewsList;




















