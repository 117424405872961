import React, {useEffect} from "react";
import {useMediaQuery} from "react-responsive";

function FilmCreditsDesktop(props) {
    return (
        <div className={'film-credits'}>
            <div>
                {props.credits[0]}
            </div>
            <div className={'film-credits-dash'}>
            </div>
            {
                ((props.credits).slice(1)).map((credit, index) => {
                    return (
                        <>
                            {index > 0 && <div className={'film-credits-name'}>{'&'}</div>}
                            <div className={'film-credits-name'}>{credit}</div>
                        </>
                    );
                })
            }
        </div>
    )
}

function FilmCreditsMobile(props) {
    return (
        <div className={'film-credits'}>
            <div>
                <div>
                    {props.credits[0]}
                </div>
                <div className={'film-credits-dash'}>
                </div>
            </div>
            {
                ((props.credits).slice(1)).map((credit, index) => {
                    return (
                        <div key={index}>
                            <div className={'film-credits-dash'}>
                            </div>
                            <div className={'film-credits-name'}>{credit}</div>
                        </div>
                    );
                })
            }
        </div>
    )
}

function FilmCredits(props) {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    return (
        <>
            <FilmCreditsMobile credits={props.credits}/>
            {/*{isTabletOrMobile && <FilmCreditsMobile credits={props.credits}/>}*/}
            {/*{!isTabletOrMobile && <FilmCreditsDesktop credits={props.credits}/>}*/}
        </>
    );
}

export default FilmCredits;