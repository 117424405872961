import importAll from "../../../util/importAll";

const KalighatFetishDetails = {
    title: "Kalighat Fetish | Kalighat Athikatha",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Short Films Images/Kalighat Fetish/",
            false,
            /\.(png|jpe?g|JPG)$/)
    ),
    details: [
        "Color",
        "16mm",
        "22 minutes",
        "1999"
    ],
    description: "The film attempts to negotiate with the duality that is associated with the ceremonial veneration of the Mother Goddess Kali- the presiding deity of Calcutta. It delves into the subliminal layers of consciousness, underlying the ritual of Kali worship. The film ruminates on the nuanced trans-sexuality that is prevalent in the ceremonial performance of male devotees cross-dressing as Kali, in an act of obsessive devotion.",
    credits: [
        ["Directed, Produced & Written By","Ashish Avikunthak"],
        ["Cinematography","Srisha Ray"],
        ["Editing","Neeraj Voralia"],
        ["Sound","Atanu Sinha"],
        ["Performer","Kaushik Gangopadhya"],

],
    screenings: [
        ["*Best Documentary, Tampere Film Festival, 2001"],
        [],
        ["*2000","World Premiere - London Film Festival"],
        ["2024","Art Against Oppression, Jadavpur University"],
        ["2024","14th Panchajanyam International Film Festival, Palakkad, Kerala"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2023","Círculo de Bellas Artes, Madrid"],
        ["2022","1st Pan-Cinema Experimental International Film Festival, Curitiba, Brazil"],
        ["2021","MUBI online streaming platform"],
        ["2020","16mm Film Festival, Harkat Studios, Mumbai"],
        ["2019","Serendipity Arts Festival, Goa"],
        ["2019","Kino Klub, Split, Croatia"],
        ["2018","Pungent Film Series, Athens, Grecce"],
        ["2018","Pungent Film Series, Athens"],
        ["2018","A Utopian Stage, Dhaka Art Summit, Bangladesh"],
        ["2016","Raw Territories Gatherings, Lisbon"],
        ["2016","Les Inattendus, Lyon"],
        ["2015","Centre for Moving Image Arts, Bard College"],
        ["2015","The Foveal Experiment, Collectif Jeune Cinema, Paris"],
        ["2015","The Foveal Experiment, Khoj International Artists’ Association, New Delhi"],
        ["2014","Plan9, Gallery Fotografic, Prague"],
        ["2014","Rice University Chao Film Festival, Houston"],
        ["2014","Black Sun, Devi Art Foundation, Delhi"],
        ["2011","Centre de Cultura Contemporània de Barcelona"],
        ["2011","Generation in Transition. New Art from India, Zacheta National Gallery for Art, Warsaw"],
        ["2010","Artprojx, London"],
        ["2009","Cornell University"],
        ["2007","Sommerszene, Salzburg"],
        ["2007","Broadway Media Centre, Nottingham"],
        ["2007","Cornerhouse, Manchester"],
        ["2006","The Hat Factory, Luton"],
        ["2006","The Peepul Centre, Leicester"],
        ["2006","Arts Picturehouse, Cambridge"],
        ["2006","Arnolfini, Bristol"],
        ["2006","Watermans, Brentford"],
        ["2006","Bite The Mango! Festival, Bradford"],
        ["2006","MAC Midlands Arts Centre, Birmingham"],
        ["2006","Tate Modern, London"],
        ["2006","Centre George Pompidou, Paris"],
        ["2006","Espace Croise, Roubaix"],
        ["2005","South Asian Film Festival, Seattle"],
        ["2005","Scratch Projects, Paris"],
        ["2004","Experimenta, Bombay & New Delhi"],
        ["2003","Queer Filmistan, San Francisco"],
        ["2002","New York Gay & Lesbian Film Festival"],
        ["2001","Los Angles Gay & Lesbian Film Festival"],
        ["2001","Tampere Film Festival"],
        ["2000","Chingari Video Fest, Madison"],
],
}

export default KalighatFetishDetails;