import importAll from "../../../util/importAll";

const EtCeteraDetails = {
    title: "Et cetera",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Short Films Images/Etcetera/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Color",
        "16mm",
        "33 minutes",
        "India & Germany",
        "1997"
    ],
    description: "Et cetera is a tetralogy of four separate films made between 1995-1997. They seek to examine the various levels at which the reality of human existence functions. In these films, specific ritual exercitations have been focused on and their movements, contemplated upon, by studying the dynamics of their etymologies. These largely mundane actions, prevailing in the vocabulary of daily living is deliberated upon. Here they are mythized in the context they appear. Thus the attempt is to move beyond the creations of grandiose actions to give semiotic credibility to often observed dianural performances, which are neither grand gestures nor major events.",
    credits: [
        ["Directed, Produced & Written by","Ashish Avikunthak"],
        ["Cinematography","Ranjan Palit","Shankar Raman","Manoj Peter"],
        ["Sound Recording","Sumit Guha"],
        ["Cast","Ramu Mandal","Dodo Bhujwala","Kaushik Gangopadhya"],
],
    screenings: [
        ["*1999","World Premiere - Dhaka Short Film Festival"],
        ["2024","Indeks, Bandung, Indonesia"],
        ["2023","Indian Institute of Technology, Gandhinagar"],
        ["2022","1st Pan-Cinema Experimental International Film Festival, Curitiba, Brazil"],
        ["2021","MUBI online streaming platform"],
        ["2019","Kino Klub, Split, Croatia"],
        ["2018","Pungent Film Series, Athens, Grecce"],
        ["2018","Artist’s Artists: The Way You Look, Burlington City Arts, Vermont"],
        ["2018","Zeitgeist Multi-Disciplinary Arts Center, New Orleans"],
        ["2017","MM Centar, Zagreb"],
        ["2015","Apeejay Arts Gallery, New Delhi"],
        ["2014","Plan9, Gallery Fotografic, Prague"],
        ["2014","Rice University Chao Film Festival, Houston"],
        ["2011","Generation in Transition. New Art from India, Zacheta National Gallery for Art, Warsaw"],
        ["2010","Gallery Chatterjee & Lal, Bombay"],
        ["2005","Retrospective of Indian Experimental Films, Cinema-Nova, Brussels"],

],
}

export default EtCeteraDetails;