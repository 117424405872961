import React from "react";
import Motif from "./Motif";
import motifs from "./motifs-list";
import {connect} from "react-redux";
import {sketchExplored} from "../../../actions/UIActions";

function SketchMobile(props) {
    const [mouseX,setMouseX] = React.useState(0);
    const [mouseY,setMouseY] = React.useState(0);
    const [rear, setRear] = React.useState(0);
    const [front,setFront] = React.useState(0);
    const size = motifs.length;
    const maxWindow = 6;
    const minDist = 50;

    const sketchFunction = (event) => {
        const clientX = event.clientX;
        const clientY = event.clientY - 0.15*window.innerHeight;
        const dist = Math.sqrt(Math.pow(mouseX-clientX,2) + Math.pow(mouseY-clientY,2));
        if(dist > minDist) {
            setMouseX(clientX);
            setMouseY(clientY);
            motifs[front].x = clientX;
            motifs[front].y = clientY;
            if((front-rear+size)%size === 2){
                props.dispatch(sketchExplored());
            }
            if((front-rear+size)%size === maxWindow){
                setRear((rear+1)%size);
            }
            setFront((front+1)%size);
        }
    };

    const diff = (front-rear+size)%size;
    const windowSize = Math.min(maxWindow,diff);

    const motifElements =  []
    for(let i=0;i<windowSize;i++) {
        const motif = motifs[(rear+i)%size];
        motifElements.push(<Motif key={i} src={motif.src} x={motif.x} y={motif.y}/>);
    }

    return (
        <div style={{
            width: "100%",
            height: "100%",
            position: "relative",
        }}
             className={'sketch-mobile'}
             onTouchMove={sketchFunction}
             onClick={sketchFunction}
        >
            {motifElements}
        </div>
    );
}

export default connect()(SketchMobile);