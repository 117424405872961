import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import {changeCursorSize, showCursorText} from "../../actions/CursorActions";
import {BIG_CURSOR_SIZE, DEFAULT_CURSOR_SIZE} from "../../constants/cursorConstants";
import {useNavigate} from "react-router-dom";
import {isDesktop, isMobile, isTablet, deviceDetect} from "react-device-detect";

function FilmBox(props) {
    const navigate = useNavigate();
    const inputRef = useRef()
    const [parallax, setParallax] = React.useState(100);

    const offset = 0.8 * window.innerHeight;
    const height = 0.8 * window.innerHeight;


    useEffect(()=> {
        const startMark = inputRef.current.offsetTop - offset;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const parallax = ((1-(winScroll-startMark)/height)*100);
        setParallax(Math.max(0,Math.min(parallax,100)));

        const scrollHandler = (event) => {
            const startMark = inputRef.current.offsetTop - offset;
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            const parallax = ((1-(winScroll-startMark)/height)*100);
            setParallax(Math.max(0,Math.min(parallax,100)));
        };
        window.addEventListener("scroll", scrollHandler);
        return(() => {
            window.removeEventListener("scroll",scrollHandler);
        })
    },[]);

    return (
        <div ref={inputRef}
             className={'film-box'}
             onMouseEnter={() => {
                 props.dispatch(showCursorText(props.title))
             }}
             onMouseLeave={() => {
                props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
             }}
             onClick={()=>{
                 props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
                 navigate(props.title.replaceAll(" ","-").replace("/","—"))
             }}
        >
            <div
                 style={{
                     backgroundImage: `url("${props.thumbnail}")`,
                     backgroundPosition: `50% ${parallax}%`
                 }}>
            </div>
            {(isMobile || isTablet) && (<div>
                <h2>
                    {props.title}
                </h2>
                <div>
                    <div>

                    </div>
                </div>
            </div>)}
        </div>
    );
}

export default connect()(FilmBox);