import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import PageTitle from "../common/PageTitle";
import Menu from "../menu/Menu";
import PageHeading from "../common/PageHeading";
import {setBg} from "../../actions/UIActions";
import Grain from "../common/Grain";

import Footer from "../common/Footer";
import pressList from "./press-list";
import PressItem from "./PressItem";

function Press(props) {

    const [left, setLeft] = useState(0);

    const scrollHandler = (e) => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setLeft(2*winScroll);
    };

    useEffect(()=> {
        props.dispatch(setBg("#c52137"));
        window.addEventListener("scroll", scrollHandler);
        return(() => {
            window.removeEventListener("scroll",scrollHandler);
        })
    },[])

    return (
        <>
            <PageTitle title="Press | Ashish Avikunthak"/>
            <div>
                <Menu></Menu>
                <div style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                >
                    <PageHeading title={"Press"} style={{
                        color: "#ffffff"
                    }}/>
                    <div className={'press-film-strip'}
                         style={{
                            backgroundPosition: -left + "px 0",
                         }}
                    >
                    </div>
                    <div className={'press'}>
                        <div>
                            {
                                pressList &&
                                pressList.map((item, index) => {
                                    return <PressItem data={item} key={index}/>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <Grain opacity={0.1}/>
        </>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

export default connect(mapStateToProps)(Press);