
const titles = [
    {title:'Home',href:'/'},
    {title:'Feature Films',href:'/feature-films'},
    {title:'Short Films',href:'/short-films'},
    {title:'Writings',href:'/writings'},
    {title:'Press',href:'/press'},
    {title:'Catalog',href:'/catalog'},
    {title:'Interviews',href:'/interviews'},
    {title:'About',href:'/about'}];

export default titles;