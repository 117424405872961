import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';


function AboutImg(props) {

    const [counter, setCounter] = useState(0);

    const updateImg = () => {
        setCounter((counter+1)%3);
    };
    return (
        <>
            <div
                className={'about-img'}
            >
                <img
                    alt={'Ashish Avikunthak'}
                    src={props.imgs[counter]}
                    onMouseEnter={updateImg}
                    // onMouseLeave={updateImg}
                />
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        size: state.cursorReducer.cursorSize
    };
}

export default connect(mapStateToProps)(AboutImg);