import importAll from "../../../util/importAll";

const AntaralDetails = {
    title: "Endnote | Antaral",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Short Films Images/Antaral_ End Note/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Color",
        "16mm",
        "18 minutes",
        "2005"
    ],
    description: "Three women reminisce about their times at school and rekindle and affirm old friendships. They share a strange secret about each other that is never made known to us. The film is a cinematic interpretation of Samuel Beckett’s 1967 dramaticule, “Come and Go”. ",
    credits: [
        ["Directed, Produced & Written by","Ashish Avikunthak"],
        ["Cast","Ashwini Deo","Aditi Deo","Kuheli Das"],
        ["Cinematography","Srisha Ray","Setu"],
        ["Sound","Chinmaya Nath"],
        ["Editing","Mahadeb Shi"],

],
    screenings: [
        ["*2006","World Premiere - Bangkok International Film Festival"],
        ["2023","Indian Institute of Technology, Mandi"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2022","1st Pan-Cinema Experimental International Film Festival, Curitiba, Brazil"],
        ["2021","MUBI online streaming platform"],
        ["2021","Sinema Transtopia, Berlin"],
        ["2021","Queer Asia Film Festival, Berlin"],
        ["2020","16mm Film Festival, Harkat Studios, Mumbai"],
        ["2019","Kino Klub, Split, Croatia"],
        ["2018","Pungent Film Series, Athens, Greece"],
        ["2018","Chennai International Documentary and Short Film Festival, Chennai"],
        ["2017","Jawaharlal Nehru University, New Delhi"],
        ["2017","Tabakalera, San Sebastián"],
        ["2016","Les Inattendus, Lyon"],
        ["2015","Endlessness of Ending: Samuel Beckett and the Mind, University of Western Sydney"],
        ["2015","Apeejay Arts Gallery, New Delhi"],
        ["2014","Plan9, Gallery Fotografic, Prague"],
        ["2014","Rice University Chao Film Festival, Houston"],
        ["2010","Human Frames, The Substation, Singapore"],
        ["2010","Human Frames, Asian Hot Shots, Berlin"],
        ["2010","Tramway Café Bar, Glasgow"],
        ["2009 ","Cornell University"],
        ["2009","Aicon Gallery, London"],
        ["2009","Museum of Contemporary Art, Roskilde"],
        ["2009","Centre George Pompidou, Paris"],
        ["2008","Jerusalem Film Festival"],
        ["2008","Expanded Forum, Berlin International Film Festival"],
        ["2007","River to River, Florence Indian Film Festival"],
        ["2007","Indo-American Arts Council Film Festival, New York"],
        ["2007","International Bunker Film Festival, Italy"],
        ["2007","Tongues on Fire - Asian Woman Film Festival, London"],
        ["2007","Let It BE! A Festival of Independent Films, Bombay"],
        ["2007","San Francisco International Asian American Film Festival"],
],
}

export default AntaralDetails;