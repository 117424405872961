import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {changeCursorSize, showCursorText} from "../../actions/CursorActions";
import {BIG_CURSOR_SIZE, DEFAULT_CURSOR_SIZE} from "../../constants/cursorConstants";
import {useNavigate} from "react-router-dom";
import {isMobile, isTablet} from "react-device-detect";

function InterviewBox(props) {
    const youtubeThumbnail = require('youtube-thumbnail');
    const [img,setImg] = useState(null);

    const openInNewTab = (url)=> {
        window.open(url, '_blank').focus();
    }

    useEffect(()=> {
        const thumbnail = youtubeThumbnail(props.href);
        setImg(thumbnail.high.url);
    },[props.href]);

    return (
        <div
             className={'interview-box'}
             onMouseEnter={() => {
                 props.dispatch(showCursorText(props.title))
             }}
             onMouseLeave={() => {
                 props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
             }}
             onClick={()=>{
                 props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE));
                 openInNewTab(props.href);
             }}
        >
            <div
                style={{
                    backgroundImage: `url("${img}")`,
                }}>
            </div>
            {(isMobile || isTablet) && (<div>
                <h2>
                    {props.title}
                </h2>
            </div>)}
        </div>
    );
}

export default connect()(InterviewBox);