import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux'
import {thunk as thunkMiddleware} from 'redux-thunk';
import RootReducer from './js/reducers/RootReducer';
import AppRouter from "./js/app/App";

const intialState = {
}

let store = createStore(
    RootReducer,
    intialState,
    applyMiddleware(thunkMiddleware) )

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppRouter store={store}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// TODO: Remove below comment after development completed. Keeping the composeEnhancers value for development period.

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// let store = createStore(RootReducer,  intialState,/* preloadedState, */ composeEnhancers(
//     applyMiddleware(thunkMiddleware)
// ));