import importAll from "../../../util/importAll";

const KalkimanthankathaDetails = {
    title: "Kalkimanthankatha | The Churning of Kalki",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Feature Films Images/4 Kalkimanthankatha/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Bengali",
        "Color & B/W",
        "DCP",
        "79 minutes",
        "India & Germany",
        "2015"
    ],
    description: "Following the footsteps of Samuel Beckett's \"Waiting for Godot\", two actor from Calcutta go to the largest gathering of humans on earth - the Hindu festival of Maha Kumbh of Allahabad in 2013, which occurs once in 12 years, to search for Kalki - the Tenth and the final avatar of Lord Vishnu. The most mysterious of Vishnu's Avatar who has been on earth but has never been found. However, an outbreak of a monumental war occurs during their quest. They then prepare themselves by reading Chairman Mao-se-Tung's \"Little Red Book\".",
    credits: [
        ["Script & Director","Ashish Avikunthak"],
        ["DoP","Basab Mullik"],
        ["Sound","Joydeep Dutta"],
        ["Editing","Pritha Chakraborty","Ashish Avikunthak"],
        ["Producer","Ashish Avikunthak"],
        ["Co-Producer","Kristina Konrad", "weltfilm Berlin"],
        ["Sound Design","Sukanta Mzumdar"],
        ["Dialogue","Sougata Mukherjee", "Ashish Avikunthak"],
        ["Music","Reena Esmail"]
    ],
    screenings: [
        ["2023","Satyajit Ray Film and Television Institute, Kolkata"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2022","Akademie der Künste, Berlin"],
        ["2022","1st Pan-Cinema Experimental International Film Festival, Curitiba, Brazil"],
        ["2021","MUBI online streaming platform"],
        ["2019","Wolf Kino, Berlin"],
        ["2019","Unseen Movies Film Festival, Athens, Greece"],
        ["2019","Clark University"],
        ["2018","American University of Beirut"],
        ["2018","Zeitgeist Multi-Disciplinary Arts Center, New Orleans"],
        ["2018","Visvabharati University, Shantiniketan"],
        ["2017","Cable Car, Providence"],
        ["2017","Jawaharlal Nehru University, New Delhi"],
        ["2017","Satyajit Ray Film and Television Institute, Kolkata"],
        ["2017","The English and Foreign Languages University, Hyderabad"],
        ["2017","Range Gallery, Kolkata"],
        ["2016","London School of Economics, London"],
        ["2016","University of Kent"],
        ["2016","Yale University"],
        ["2016","New York University"],
        ["2015","Gallery Chatterjee & Lal, Mumbai"],
        ["2015","University of Rhode Island"],
    ],
    trailer: "126086457",
}

export default KalkimanthankathaDetails;