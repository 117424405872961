import importAll from "../../../util/importAll";

const VrindavaniVairagyaDetails = {
    title: "Vrindavani Vairagya | Dispassionate Love",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Feature Films Images/6 Vrindavani Vairagya/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Bengali",
        "Color & B/W",
        "DCP",
        "91 minutes",
        "India & Germany",
        "2017"
    ],
    description: "Recalling memories of a friend who committed suicide, three lovers slowly slide into an anguished labyrinth of desire, loss and longing. They entangle in a colliding maze of forsaken loves, failed expectations and imperfect anticipations. A disintegrating web arises in which love exists, but as dispassionate yearning. Here affection is an indifferent desire that burns the soul to death.",
    credits: [
        ["Script & Director","Ashish Avikunthak"],
        ["Director of Photography","Basab Mullick"],
        ["Sound","Joydeep Dutta"],
        ["Editing","Pritha Chakraborty", "Ashish Avikunthak"],
        ["Dialogue","Sougata Mukherjee","Ashish Avikunthak"],
        ["Sound Design","Sukanta Mazumdar"],
        ["Producer","Ashish Avikunthak"],
        ["Co-Producer","Kristina Konrad","weltfilm GmbH Berlin"]
    ],
    screenings: [
        ["*2019","World Premiere - Wolf Kino, Berlin"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2022","Satyajit Ray Film and Television Institute, Kolkata"],
        ["2022","1st Pan-Cinema Experimental International Film Festival, Curitiba, Brazil"],
        ["2022","Film & Television Institute of India, Pune"],
        ["2020","Closing Film, Arthouse Asia Film Festival, Kolkata"],
        ["2019","Gallery Chatterjee & Lal, Mumbai"],
],

    trailer: "260614293",
}

export default VrindavaniVairagyaDetails;