import React, {useEffect} from "react";

function HomeTitle(props) {
    const title = 'Ashish Avikunthak';
    const up = true;

    return (
            <div className={'avikunthakHome'}>
                <span className={'avikunthakTitle'}>
                {up?title.toUpperCase():title}
                </span>
            </div>
    );
}

export default (HomeTitle);