import genealogy from "../../../assets/writings/In Search of a Genealogy_Experimental, Avant-Garde or Prayoga_ Deep Focus.pdf";
import nation from "../../../assets/writings/Nation and Narration, Art India.pdf";
import hundred from "../../../assets/writings/Hundred Years of Experimentation (1913-2013).pdf";
import conjuring from "../../../assets/writings/Conjuring River, Imagining Civilization_Saraswati, Archaeology and Science in India.pdf";
import crypto from "../../../assets/writings/Cryptographic Imagination Indus Script and the Project of Scientific Decipherment.pdf";
import archaeo from "../../../assets/writings/ASI & Postcolonial Archaeology in Lydon & Rizvi, 2010.pdf";
import ambi from "../../../assets/writings/_Ambivalent Heritage_Between Affect and Ideology in a Colonial Cemetery_.pdf";
import disci from "../../../assets/writings/JSA- Mortimeer Wheeler, 2002.pdf";
import anatomy from "../../../assets/writings/Anatomy of Disposession in Ucko & Layton, 1999.pdf";

const articles = [
    {
        date: 2014,
        title: "In Search of a Genealogy: Experimental, Avant-Garde or Prayoga?",
        publisher: "Deep Focus",
        link: genealogy
    },
    {
        date: 2014,
        title: "Nation and Narration",
        publisher: "Art India",
        link: nation
    },
    {
        date: 2013,
        title: "Hundred Years of Experimentation (1913-2013): A Retrospective of Indian cinema and video",
        link: hundred
    },
    {
        date: 2011,
        title: "Conjuring River, Imagining Civilization: Saraswati, Archaeology and Science in India.",
        publisher: "Contributions to Indian Sociology Vol. 45(1): 55–83",
        link: conjuring
    },
    {
        date: 2010,
        title: "“Cryptographic Imagination: Indus Script and the Project of Scientific Decipherment”",
        publisher: "Indian Economic and Social History Review, Vol. 47(2): 141–77.",
        link: crypto
    },
    {
        date: 2010,
        title: "Archaeological Survey of India and the science of postcolonial archaeology, in Jane Lyndon & Uzma Rizvi (eds.)",
        publisher: "Handbook of Postcolonial Archaeology (Walnut Creek: Left Coast Press).",
        link: archaeo
    },
    {
        date: 2006,
        title: "Ambivalent Heritage: Between Affect and Ideology in a Colonial Cemetery",
        publisher: "Journal of Material Culture, Vol. 11(3): 339–363.",
        link: ambi
    },
    {
        date: 2002,
        title: "Visions of Discipline: Sir Mortimer Wheeler and the Archaeological Method in India (1944-48)",
        publisher: "Journal of Social Archaeology, Vol. 2(3): 378–401.",
        link: disci
    },
    {
        date: 1999,
        title: "“The Anatomy of displacement: a study in the displacement of the tribals from their traditional landscape in the Narmada Valley due to Sardar Sarovar project” in Peter Ucko & Robert Layton (eds.),",
        publisher: "The Archaeology and Anthropology of Landscape, London: Routeledge.",
        link: anatomy
    },
];

export default articles;