import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import AvikunthakHome from "../containers/home/AvikunthakHome";
import Cursor from "../containers/cursor/Cursor";
import '../../App.css'
import FeatureFilms from "../containers/films/featureFilms/FeatureFilms";
import ShortFilms from "../containers/films/shortFilms/ShortFilms";
import Writings from "../containers/writings/Writings";
import FilmPage from "../containers/films/filmPage/FilmPage";
import {changeCursorSize} from "../actions/CursorActions";
import {DEFAULT_CURSOR_SIZE} from "../constants/cursorConstants";
import {connect} from "react-redux";
import About from "../containers/about/About";
import Press from "../containers/press/Press";
import Interview from "../containers/interview/Interview";
import Catalog from "../containers/catalog/Catalog";
import ScrollToTop from "../util/scrollToTop";

export const HOME = '/';

const AppRouter = ( props ) => {
    return (<div style={{
            width: "100vw",
            minHeight: "100vh",
            display: "block",
            position: "relative",
            overflowX: "hidden",
            background: props.color
        }}>
            <Cursor/>
            <Router>
                <Routes onChange={changeCursorSize(DEFAULT_CURSOR_SIZE)}>
                    <Route exact path={HOME}
                           element={<AvikunthakHome/>}
                    />
                    <Route exact path={HOME+"feature-films/"}
                           element={<FeatureFilms/>}
                    />
                    <Route path={HOME+"feature-films/:filmId"}
                             element={<FilmPage/>}
                    />
                    <Route exact path={HOME+"short-films/"}
                           element={<ShortFilms/>}
                    />
                    <Route path={HOME+"short-films/:filmId"}
                           element={<FilmPage/>}
                    />
                    <Route exact path={HOME+"writings/"}
                              element={<Writings/>}
                    />
                    <Route exact path={HOME+"press/"}
                           element={<Press/>}
                    />
                    <Route exact path={HOME+"catalog/"}
                           element={<Catalog/>}
                    />
                    <Route exact path={HOME+"interviews/"}
                           element={<Interview/>}
                    />
                    <Route exact path={HOME+"about/"}
                           element={<About/>}
                    />
                </Routes>
                <ScrollToTop/>
            </Router>
        </div>
)};

function mapStateToProps(state) {
    return {
        color: state.uiReducer.color
    };
}

export default connect(mapStateToProps)(AppRouter);