import importAll from "../../../util/importAll";

const AapothkalinTrikalikaDetails = {
    title: "Aapothkalin Trikalika | The Kali of Emergency ",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Feature Films Images/5 Aapothkalin Trikalika/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Bengali",
        "Color",
        "DCP",
        "79 minutes",
        "India & Germany",
        "2016"
    ],
    description: "During social and political turmoil what is the manifestation of divine intervention? How do the Gods and Goddess act in the volatility of the contemporary world? If they walk on earth as men and women, how they endure the chaos of modernity? Centering on the terrible and the majestic incarnations of Goddess Kali and her celestial avatars, this film is a metaphysical contemplation in times of perpetual emergencies.",
    credits: [
        ["Screenplay, Director & Producer","Ashish Avikunthak"],
        ["Cinematography","Basab Mullick"],
        ["Editing","Pankaj Rishi Kumar", "Nikon", "Ashish Avikunthak"],
        ["Dialogue","Moloy Mukherjee"],
        ["Cast","Ruma Poddar","Saswati Biswas","Sharmistha Nag"," Siddhartha Banerjee","Sandeep Mukherjee","Sougata Mukherjee","Marylea Madiman","Satakshi Nandi","Sahali Goswami","Mishka Halim"],
        ["Sound Design","Sukanta Mazumdar"],
        ["Co-Producer","Kristina Konrad","weltfilm GmbH Berlin"]
    ],
    screenings: [
        ["*2017","World Premiere - Berlin Film Festival"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2023","International Film Festival Rotterdam"],
        ["2022","1st Pan-Cinema Experimental International Film Festival, Curitiba, Brazil"],
        ["2020","Arsenal - Institute for Film and Video Art, Berlin"],
        ["2019","Kaunas University of Technology, Kaunas, Lithuania"],
        ["2019","Aicon Gallery, New York"],
        ["2019","Wolf Kino, Berlin"],
        ["2018","University of Rhode Island"],
        ["2018","A Utopian Stage, Dhaka Art Summit, Bangladesh"],
        ["2017","Arbeitsgemeinschaft Kommunales Kino, Freiburg"],
        ["2017","Tufts University"],
        ["2017","Chicago Architecture Biennial"],
        ["2017","Indian Film Festival of Melbourne"],
        ["2017","Gallery Chatterjee & Lal, Mumbai"],

],

    trailer: "161835249",
}

export default AapothkalinTrikalikaDetails;