import React, {useEffect, useRef} from "react";

function FilmGallery(props) {
    const inputRef = useRef()
    const [parallax, setParallax] = React.useState(100);
    const offset = window.innerHeight;

    useEffect(()=> {
        const LIMIT = 400;
        const startMark = inputRef.current.offsetTop - offset;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const parallax = ((((winScroll-startMark)/LIMIT)*props.listOfImages.length))%props.listOfImages.length;
        setParallax(Math.floor(parallax));

        const scrollHandler = (event) => {
            const startMark = inputRef.current.offsetTop - offset;
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            const parallax = ((((winScroll-startMark)/LIMIT)*props.listOfImages.length))%props.listOfImages.length;
            setParallax(Math.floor(parallax));
        };
        window.addEventListener("scroll", scrollHandler);
        return(() => {
            window.removeEventListener("scroll",scrollHandler);
        })
    },[props.listOfImages]);

    return (
        <div
            ref={inputRef}
            className={'film-gallery'}
            style={{
                backgroundImage: `url('${props.listOfImages[parallax]}')`
            }}
        >
        </div>
    )
}

export default FilmGallery;