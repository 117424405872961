import about_img from "../../../assets/about/about_img.jpeg";
import about_img2 from "../../../assets/about/ashish2.jpeg";
import about_img3 from "../../../assets/about/ashish3.jpeg";

const AboutData = {
    about_pics: [about_img, about_img2, about_img3],
    para1: "Ashish Avikunthak is an Indian avant-garde filmmaker, film theorist, archaeologist and cultural anthropologist. His films have been shown worldwide in film festivals, galleries and museums. His films have been the subject of more than a dozen retrospectives, eighteen solo shows and they have been shown worldwide in major film festivals, group shows and museums. He has been named Future Greats 2014 by Art Review. His films have been shown worldwide in film festivals, galleries and museums. His films have been the subject of more than a dozen retrospectives, sixteen solo shows and they have been shown worldwide in major film festivals, group shows and museums. Notable screenings were at the Tate Modern, London (2006), Centre George Pompidou, Paris (2006, 2008, 2011), Taipei Biennial (2012), Shanghai Biennial (2014), along with Locarno Film Festival (2007, 2011), Rotterdam Film Festival (2007, 2011, 2021, 2023, 2024), and Berlin Film Festival (2008, 2017) among many other locations.",
    para2: "He has had retrospective of his works at MUBI (2021-22), Wolf Kino, Berlin (2019), Kino Klub, Split, Croatia (2019), Pungent Film Series, Athens (2018), Bard College (2015), Apeejay Arts Gallery, New Delhi (2015), Rice University (2014), Signs Festival, Trivandrum (2013), Festival International Signes de Nuit, Paris (2012), Yale University (2008), and National Centre for Performing Arts, Mumbai (2008) and Les Inattendus, Lyon (2006). In 2011, he was short listed for the Skoda Prize for Indian Contemporary Art.",
    para3: "He is the author of “Bureaucratic Archaeology: State, Science and Past in Postcolonial India”, published by Cambridge University Press in 2021 and his scholarly works have been published in the Journal of Social Archaeology, Journal of Material Culture, Contributions to Indian Sociology and The Indian Economic and Social History Review among other publications. He has a PhD in cultural anthropology from Stanford University and before coming to URI, he had taught at Yale University.",
    para4: "He has a PhD in Cultural and Social Anthropology from Stanford University and has earlier taught at Yale University. He is now a Professor in Film/Media at Harrington School of Communication, University of Rhode Island.",

    addr1: {
        title: "Short Film Distribution (Europe) -",
        addrln1: "Light Cone",
        addrln2: "12 rue des Vignoles, 75020 Paris, France"
    },
    mail: "avikunthak@gmail.com"
}

export default AboutData;