import React, {useEffect} from "react";

function Grain(props) {
    return (
        <div id="grain" style={{
            opacity: props.opacity || 0.1
        }}>

        </div>
    );
}

export default (Grain);