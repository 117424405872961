import importAll from "../../../util/importAll";

const KathoUpanishadDetails = {
    title: "Katho Upanishad",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Feature Films Images/2 Katho Upanishd/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Hindi",
        "Color",
        "35mm",
        "82 minutes",
        "India",
        "2011"
    ],
    description: "“Teach me the path beyond death,” asks Nachiketa to Yama, the God of Death. ‘Katho Upanishad’ is a film that expounds on this basic quest of human existence. It is a film about a man's pursuit for nirvana. The film centers on the metaphysical dialogue between Nachiketa and Yama. It is an adaptation of a two and half thousand years old Sanskrit treatise of the same name, where Yama instructs Nachiketa about the path towards enlightenment. Structurally, the film is a triad with three chapters – the quest, the dialogue and the final liberation",
    credits: [
        ["Screenplay, Production & Direction", "Ashish Avikunthak"],
        ["Starring", "Ram Gopal Bajaj", "Suvrat Joshi"],
        ["Cinematography", "Setu", "Basab Mullick"],
        ["Editing", "Pankaj Rishi Kumar"],
        ["Audiography", "Dipankar Chaki", "Anirban Sengupta"],
        ["Dialogue", "Moloy Mukherjee","Ram Gopal Bajaj"],
        ["Sound", "Preetam Das"],
        ["Executive Producer", "Ashwini Deo"],
        ["Associate Producer", "Riju Bajaj"],
        ["Associate Director","Moloy Mukherjee"]
    ],
    screenings: [
        ["2023","Patna Cine Festa"],
        ["2023","Bangladesh Shilpakala Academy, Dhaka"],
        ["2021","MUBI online streaming platform"],
        ["2019","Wolf Kino, Berlin"],
        ["2018","Whitney Humanities Center, Yale University"],
        ["2018","International Spiritual Film Festival India, Bhopal"],
        ["2015","Centre for Moving Image Arts, Bard College"],
        ["2015","Apeejay Arts Gallery, New Delhi"],
        ["2014","University of Massachusetts, Dartmouth"],
        ["2012","Film & Television Institute of India, Pune"],
        ["2012","Gallery Chatterjee & Lal, Mumbai"],
        ["2011","Mumbai International Film Festival"],
    ],
    trailer: "162221717",
}

export default KathoUpanishadDetails;