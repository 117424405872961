import aicon2024 from "../../../assets/catalog/15 Aicon - Vrindavani Vairagya Catalogue 2024.pdf";
import chat23 from "../../../assets/catalog/14 Chatterjee & Lal - Rummaging for Past 2023.pdf";
import chat22 from "../../../assets/catalog/13 Chatterjee & Lal - Namanush Premer Kothamala 2022.pdf";
import aicon19 from "../../../assets/catalog/10 Aicon - Aapothkalin Trikalika Catalogue 2019.pdf";
import chat19 from "../../../assets/catalog/12 Chatterjee & Lal - Vrindavani Vairagya 2019.pdf";
import wolf19 from "../../../assets/catalog/11 Wolf Kino Catalog 2019.pdf";
import chat17 from "../../../assets/catalog/9 Chatterjee & Lal - Aapothkalin Trikalika Catalogue 2017.pdf";
import range17 from "../../../assets/catalog/8 Range Gallery - Kalkimanthakatha 2017.pdf";
import chat15 from "../../../assets/catalog/7 Chatterjee & Lal - Kalkimanthankatha Catalog 2015.pdf";
import exp15 from "../../../assets/catalog/5 Experimenter - Rati Chakravyuh 2015.pdf";
import aicon14 from "../../../assets/catalog/6 Aicon - Rati Chakravyuh 2014.pdf";
import chat14 from "../../../assets/catalog/4 Chatterjee & Lal - Rati Chakravyuh Catalog 2014.pdf";
import aicon12 from "../../../assets/catalog/2 Aicon - Kalighat & Vakratunda 2012.pdf";
import chat12 from "../../../assets/catalog/3 Chatterjee & Lal - Katho Upanishad 2012.pdf";
import chat10 from "../../../assets/catalog/1 Chatterjee & Lal - Vakratunda 2010.pdf";

import thum1 from "../../../assets/catalog/thumbnails/aicon24.png";
import thum2 from "../../../assets/catalog/thumbnails/chat23.png";
import thum3 from "../../../assets/catalog/thumbnails/chat22.png";
import thum4 from "../../../assets/catalog/thumbnails/aicon19.png";
import thum5 from "../../../assets/catalog/thumbnails/chat19.png";
import thum6 from "../../../assets/catalog/thumbnails/wolf19.png";
import thum7 from "../../../assets/catalog/thumbnails/chat17.png";
import thum8 from "../../../assets/catalog/thumbnails/range17.png";
import thum9 from "../../../assets/catalog/thumbnails/chat15.png";
import thum10 from "../../../assets/catalog/thumbnails/exp15.png";
import thum11 from "../../../assets/catalog/thumbnails/aicon14.png";
import thum12 from "../../../assets/catalog/thumbnails/chat14.png";
import thum13 from "../../../assets/catalog/thumbnails/chat12.png";
import thum14 from "../../../assets/catalog/thumbnails/aicon12.png";
import thum15 from "../../../assets/catalog/thumbnails/chat10.png";

const catalogList = [
    {
        title: "Exhibition Catalog of Vrindavani Vairagya, Aicon Gallery, New York, 2024",
        link: aicon2024,
        thumbnail: thum1,
    },
    {
        title: "Exhibition Catalog of Rummaging for Past, Chatterjee & Lal, Mumbai, 2023",
        link: chat23,
        thumbnail: thum2,
    },
    {
        title: "Exhibition Catalog of Glossary of Non-Human Love, Chatterjee & Lal, Mumbai, 2022",
        link: chat22,
        thumbnail: thum3,
    },
    {
        title: "Exhibition Catalog of Kali of Emergency, Aicon Gallery, New York, 2019",
        link: aicon19,
        thumbnail: thum4,
    },
    {
        title: "Exhibition Catalog of Vrindavani Vairagya, Chatterjee & Lal, Mumbai, 2019",
        link: chat19,
        thumbnail: thum5,
    },
    {
        title: "Exhibition Catalog of Retrospective at Cinema Wolf Kino, Berlin, 2019",
        link: wolf19,
        thumbnail: thum6,
    },
    {
        title: "Exhibition Catalog of Aapothkalin Trikalika, Chatterjee & Lal, Mumbai, 2017",
        link: chat17,
        thumbnail: thum7,
    },
    {
        title: "Exhibition Catalog of Kalkimanthankatha, Range Gallery, Kolkata, 2017",
        link: range17,
        thumbnail: thum8,
    },
    {
        title: "Exhibition Catalog of Kalkimanthankatha, Chatterjee & Lal, Mumbai, 2015",
        link: chat15,
        thumbnail: thum9,
    },
    {
        title: "Exhibition Catalog of Rati Chakravyuh, Experimenter Gallery, Kolkata, 2015",
        link: exp15,
        thumbnail: thum10,
    },
    {
        title: "Exhibition Catalog of Rati Chakravyuh, Aicon Gallery, New York, 2014",
        link: aicon14,
        thumbnail: thum11,
    },
    {
        title: "Exhibition Catalog of Rati Chakravyuh, Chatterjee & Lal, Mumbai, 2014",
        link: chat14,
        thumbnail: thum12,
    },
    {
        title: "Exhibition Catalog of Katho Upanishad, Chatterjee & Lal, Mumbai, 2012",
        link: chat12,
        thumbnail: thum13,
    },
    {
        title: "Exhibition Catalog of Vakratunda Swaha & Kalighat Fetish, Aicon Gallery, New York, 2012",
        link: aicon12,
        thumbnail: thum14,
    },
    {
        title: "Exhibition Catalog of Vakratunda Swaha, Chatterjee & Lal, Mumbai, 2010",
        link: chat10,
        thumbnail: thum15,
    },
];

export default catalogList;