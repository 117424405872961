import { useEffect } from 'react';
import {connect} from 'react-redux';
import {changeCursorSize} from "../../actions/CursorActions";
import {burgerClose, burgerOpen} from "../../actions/BurgerActions";
import {DEFAULT_CURSOR_SIZE} from "../../constants/cursorConstants";

function SetCursor(props) {
    useEffect(() => {
        props.dispatch(changeCursorSize(DEFAULT_CURSOR_SIZE))
    }, []);

    useEffect(() => {
        props.dispatch(burgerClose())
    }, []);
    return null;
};

export default connect()(SetCursor);