import importAll from "../../../util/importAll";

const RummagingForPastsDetails = {
    title: "Rummaging For Pasts: Excavating Sicily Digging Bombay",
    images: importAll(
        require.context(
            "../../../../assets/film-images/Short Films Images/Rummaging For Pasts/",
            false,
            /\.(png|jpe?g)$/)
    ),
    details: [
        "Color",
        "DV",
        "27 minutes",
        "2001"
    ],
    description: "Rummaging for Pasts is an experimental juxtaposition of two cinematic documents: the video diary of an international archaeological excavation and a collection of assorted eight millimeter found footage. The archaeologists, digging on the site of Monte Pollizzo in Sicily, are in pursuit of an ephemeral past, its people and its meaning; the found footage, excavated from a roadside junkyard in a Bombay flea market, embodies forgotten images of a collective memory.",
    credits: [
        ["Directed, Produced & Written by","Ashish Avikunthak"],
        ["Executive Producer","Ashwini Deo"],
        ["Editing","Danna Rose","Ashish Avikunthak"],
        ["Sound","David Hoe"],
],
    screenings: [
        ["2023","Chatterjee & Lal, Mumbai"],
        ["2021","MUBI online streaming platform"],
        ["2020","Joukowsky Institute for Archaeology and the Ancient World, Brown University"],
        ["2017","Coventry Biennial of Contemporary Art, England"],
        ["2015","The Foveal Experiment, Collectif Jeune Cinema, Paris"],
        ["2015","The Foveal Experiment, Khoj International Artists’ Association, New Delhi"],
        ["2004","Vikalp- Films for Freedom, Bombay"],
        ["2003","World Archaeology Congress, Washington"],
        ["2001","\"Narrative Pasts | Past Narratives\", Conference on Archaeology, Stanford"],

],
}

export default RummagingForPastsDetails;